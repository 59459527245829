import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useLazyQuery, gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('Orders Bulk Action (Page)');
export const onPageStarted = domain.event();

const listCarriers = gql`
  query listCarriers {
    listCarriers {
      name
      value
    }
  }
`;

const createDeliveryRoute = gql`
  mutation createDeliveryRoute($data: DeliveryRouteInput!) {
    createDeliveryRoute(data: $data)
  }
`;

const listOrdersQueryExt = gql`
  query listOrders($data: ListOrdersInputExt) {
    listOrdersExt(data: $data) {
      orders {
        id
        shipBy
        status
        zone
        carrier
        deliveryInstruction
        zipCode
        customer {
          firstName
          lastName
          email
        }
        billing {
          firstName
          lastName
          address1
          state
          postcode
          country
          city
          phone
        }
        paymentStatus
        wcTotal
        paymentCard {
          lastFour
          updatedCard
        }
        wcCreated
        wcNSInfo {
          nsLink
          salesOrderNumber
          internalId
        }
        wcRaw
      }
      total
      range
    }
  }
`;


const setOrdersStatusMutation = gql`
  mutation setOrdersStatus($orders: [ID]!, $status: String!) {
    setOrdersStatus(orders: $orders, status: $status) {
      id
      status
    }
  }
`;

const orderTypes = [
  'subscription',
  'order'
].map(s => ({ id: s, text: s, value: s }));


const perPage = 40;

export function PageOrdersBulkAction(props) {
  const [orderType, setOrderType] = React.useState(orderTypes[0].id);
  const [status, setStatus] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(sub(new Date(), { days: 1 }));
  const [toDate, setToDate] = React.useState(add(new Date(), { days: 1 }));
  const [filter, setFilter] = React.useState();
  const [ordersForBulkAction, setOrdersForBulkAction] = React.useState([]);
  const [bulkStatus, setBulkStatus] = React.useState('');
  const [selectedOrder, setSelectedOrder] = React.useState();

  React.useEffect(() => {
    setStatus([]);
    setBulkStatus('');
  }, [orderType])

  React.useEffect(() => {
    if (fromDate && toDate) {
      setFilter({
        from: format(fromDate, "yyyy-MM-dd"),
        to: format(toDate, "yyyy-MM-dd"),
        statuses: status,
        orderType
      });
    } else {
      setFilter(null);
    }
  }, [status, fromDate, toDate, orderType]);

  const orderStatuses = React.useMemo(() => {
    if (orderType === 'subscription') {
      return [
        'op-subscription',
        'op-paused',
        'op-incomplete',
        'op-qa-subs',
        'op-sub-cancel'
      ].map(s => ({ id: s, text: s, value: s }));
    }
    return [
      'picked',
      'packed',
      'shipped',
      'delivered',
      'pending',
      'processing'
    ].map(s => ({ id: s, text: s, value: s }))
  }, [orderType]);

  const [getOrdersExt, { loading, error, data, fetchMore, refetch }] = useLazyQuery(listOrdersQueryExt, {
    errorPolicy: 'all'
  });

  const [setOrdersStatus, { loading: loadingMutation, error: errorMutation }] = useMutation(setOrdersStatusMutation);

  const hasNextPage = React.useMemo(() => {
    return data?.listOrdersExt?.total > data?.listOrdersExt?.range[1];
  }, [data]);

  const loadMore = () => {
    const range = [data?.listOrdersExt?.range[0] + perPage, data?.listOrdersExt?.range[1] + perPage];
    fetchMore({
      variables: {
        data: {
          filter,
          range,
          index: 'created'
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => (
        {
          listOrdersExt: {
            total: fetchMoreResult?.listOrdersExt?.total,
            range: fetchMoreResult?.listOrdersExt?.range,
            orders: [...prev.listOrdersExt.orders, ...fetchMoreResult.listOrdersExt.orders]
          }
        }
      )
    })
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });


  React.useEffect(() => {
    if (filter) {
      getOrdersExt({
        variables: {
          data: {
            filter,
            range: [0, perPage],
            index: 'created'
          }
        }
      })
    }
  }, [filter, getOrdersExt]);

  const handleChangeBulkStatus = () => {
    // console.log('---handle bulk---', ordersForBulkAction, bulkStatus);
    setOrdersStatus({ variables: { orders: ordersForBulkAction, status: bulkStatus } });
    setOrdersForBulkAction([]);
  }

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Dropdown placeholder="Order Type" options={orderTypes} selection value={orderType} onChange={(e, { value }) => setOrderType(value)} />
          <Dropdown placeholder="Status" clearable options={orderStatuses} multiple selection value={status} onChange={(e, { value }) => setStatus(value)} />
          <SemanticDatepicker placeholder="Created From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="Created To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
        </Menu.Item>
      </Menu>
      <br />
      <Menu compact>
        <Menu.Item>
          <Dropdown placeholder="Bulk Status" clearable options={orderStatuses} selection value={bulkStatus} onChange={(e, { value }) => setBulkStatus(value)} />
          <Button onClick={handleChangeBulkStatus} disabled={!ordersForBulkAction.length || !bulkStatus}>Bulk Update</Button>
        </Menu.Item>
      </Menu>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1">#</Table.HeaderCell>
            <Table.HeaderCell width="1"></Table.HeaderCell>
            <Table.HeaderCell width="1">ID</Table.HeaderCell>
            <Table.HeaderCell width="1">Status</Table.HeaderCell>
            <Table.HeaderCell width="1">Total</Table.HeaderCell>
            <Table.HeaderCell width="1">Created</Table.HeaderCell>
            <Table.HeaderCell width="1">Customer</Table.HeaderCell>
            <Table.HeaderCell width="1">Netsuite Link</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listOrdersExt?.orders?.map((order, ind) => (
              <Table.Row key={order.id}>
                <Table.Cell>{ind + 1}/{data?.listOrdersExt?.total}</Table.Cell>
                <Table.Cell><Checkbox checked={ordersForBulkAction.includes(order.id)} onChange={(e, { checked }) => {
                  setOrdersForBulkAction(state => {
                    if (checked) {
                      return [...state, order.id];
                    }
                    return state.filter(id => id !== order.id);
                  })
                }} /></Table.Cell>
                <Table.Cell onClick={() => setSelectedOrder(order)}>{order.id}</Table.Cell>
                <Table.Cell>{order.status}</Table.Cell>
                <Table.Cell>{order.wcTotal}</Table.Cell>
                <Table.Cell>{order.wcCreated}</Table.Cell>
                <Table.Cell>{order.billing?.firstName} {order.billing?.lastName}</Table.Cell>
                <Table.Cell><a href={order.wcNSInfo?.nsLink} target="_blank">{order.wcNSInfo?.nsLink}</a></Table.Cell>
              </Table.Row>
            ))
          }
          {
            (loading || hasNextPage) && (
              <Table.Row>
                <Table.Cell>
                  <div ref={sentryRef}>Loading...</div>
                </Table.Cell>
              </Table.Row>
            )
          }

        </Table.Body>
      </Table>
      <Modal open={!!selectedOrder} dimmer="blurring">
        <Modal.Header>Order Details #{selectedOrder?.id}</Modal.Header>
        <Modal.Content scrolling>
          {parseWcRaw(selectedOrder)}
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={() => setSelectedOrder(undefined)} />
        </Modal.Actions>
      </Modal>
    </PrivateRoute>
  )
}

function parseWcRaw(order) {
  // console.log('---parsed---', order);
  const { wcRaw = '' } = order || {};

  const wcJson = React.useMemo(() => {
    try {
      const json = JSON.parse(wcRaw);
      return json;
    } catch (ex) { }
    return {};
  }, [wcRaw]);

  // console.log('---wcRaw---', wcJson);

  return (
    <div>
      <pre>
        {JSON.stringify(wcJson, null, ' ')}
      </pre>
    </div>
  )
}