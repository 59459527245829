import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useLazyQuery, gql } from '@apollo/client';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';

import PrivateRoute from 'ui/components/PrivateRoute';
import MainMenu from '../../components/MainMenu';

const domain = createDomain('OrdersReport (Page)');
export const onPageStarted = domain.event();


const listOrdersQuery = gql`query QueryListOrders($status: String, $from: String, $to: String, $nextPage: String){
  listOrders(data: {status: $status, from: $from, to: $to, nextPage: $nextPage}){
    orders {
      id
      status
      shipBy
      customer {
        firstName
        lastName
      }
      zipCode
      zone
    }
  }
}`;

const orderStatuses = ['processing', 'picked', 'packed', 'shipping', 'shipped', 'hold'];
const statuses = orderStatuses.map(s => ({ id: s, text: s, value: s }));

export function PageOrdersReport(props) {
  const [getOrders, { loading, error, data }] = useLazyQuery(listOrdersQuery, { errorPolicy: 'all' });
  const [ordersList, setOrdersList] = React.useState([]);
  const [status, setStatus] = React.useState('');
  const [fromDate, setFromDate] = React.useState(sub(new Date(), { days: 1 }));
  const [toDate, setToDate] = React.useState(add(new Date(), { days: 1 }));

  React.useEffect(() => {
    if (fromDate && toDate) {
      getOrders({ variables: { status, nextPage: 'all', from: format(fromDate, "yyyy-MM-dd"), to: format(toDate, "yyyy-MM-dd") } });
    }
  }, [status, fromDate, toDate, getOrders]);

  React.useEffect(() => {
    if (data) {
      const list = (data.listOrders ?? {}).orders;
      const obj = list?.reduce((m, d) => {
        const { id, shipBy, status } = d;
        const key = `${shipBy}_${status}`;
        m[key] = m[key] || { id: key, shipBy, status, arr: [] };
        m[key].arr.push(id);
        return m;
      }, {});
      obj && setOrdersList(Object.values(obj));
    }
  }, [data]);


  const handleExportToCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," +
      "status, shipBy, orders, count\n" +
      ordersList.map(row => `${row.status},${row.shipBy},${row.arr.join('; ')},${row.arr.length}`).join('\n');
    var encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Dropdown placeholder="Status" clearable options={statuses} selection value={status} onChange={(e, { value }) => setStatus(value)} />
        </Menu.Item>
        <Menu.Item>
          <SemanticDatepicker placeholder="Ship From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
        </Menu.Item>
        <Menu.Item>
          <SemanticDatepicker placeholder="Ship To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
        </Menu.Item>
        <Menu.Item>
          <Button onClick={handleExportToCSV}>Export to CSV</Button>
        </Menu.Item>
      </Menu>

      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>Ship By</Table.HeaderCell>
            <Table.HeaderCell>Orders</Table.HeaderCell>
            <Table.HeaderCell>Count</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            ordersList?.map(order => (
              <Table.Row key={order.id}>
                <Table.Cell>{order.status}</Table.Cell>
                <Table.Cell>{order.shipBy}</Table.Cell>
                <Table.Cell>{order.arr.join(', ')}</Table.Cell>
                <Table.Cell>{order.arr.length}</Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </PrivateRoute>
  )
}