import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useLazyQuery, gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('Payment Report (Page)');
export const onPageStarted = domain.event();

const listCarriers = gql`
  query listCarriers {
    listCarriers {
      name
      value
    }
  }
`;

const createDeliveryRoute = gql`
  mutation createDeliveryRoute($data: DeliveryRouteInput!) {
    createDeliveryRoute(data: $data)
  }
`;

const listOrdersQueryExt = gql`
  query listOrders($data: ListOrdersInputExt) {
    listOrdersExt(data: $data) {
      orders {
        id
        shipBy
        status
        zone
        carrier
        deliveryInstruction
        zipCode
        customer {
          firstName
          lastName
          email
        }
        billing {
          firstName
          lastName
          address1
          state
          postcode
          country
          city
          phone
        }
        paymentStatus
        wcTotal
        paymentCard {
          lastFour
          updatedCard
        }
        wcCreated
      }
      total
      range
    }
  }
`;

const paymentStatuses = [
  '',
  'payment_success',
  'payment_failed',
  'payment_none'
].map(s => ({ id: s, text: s, value: s }));

const perPage = 40;

export function PagePaymentReport(props) {
  const [status, setStatus] = React.useState(paymentStatuses[2].id);
  const [fromDate, setFromDate] = React.useState(sub(new Date(), { days: 1 }));
  const [toDate, setToDate] = React.useState(add(new Date(), { days: 1 }));

  const [filter, setFilter] = React.useState();

  React.useEffect(() => {
    if (fromDate && toDate) {
      setFilter({
        from: format(fromDate, "yyyy-MM-dd"),
        to: format(toDate, "yyyy-MM-dd"),
        paymentStatus: [status],
      });
    } else {
      setFilter(null);
    }
  }, [status, fromDate, toDate]);

  const [getOrdersExt, { loading, error, data, fetchMore, refetch }] = useLazyQuery(listOrdersQueryExt, {
    errorPolicy: 'all'
  });


  const hasNextPage = React.useMemo(() => {
    return data?.listOrdersExt?.total > data?.listOrdersExt?.range[1];
  }, [data]);

  const loadMore = () => {
    const range = [data?.listOrdersExt?.range[0] + perPage, data?.listOrdersExt?.range[1] + perPage];
    fetchMore({
      variables: {
        data: {
          filter,
          range,
          index: 'created'
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => (
        {
          listOrdersExt: {
            total: fetchMoreResult?.listOrdersExt?.total,
            range: fetchMoreResult?.listOrdersExt?.range,
            orders: [...prev.listOrdersExt.orders, ...fetchMoreResult.listOrdersExt.orders]
          }
        }
      )
    })
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });


  React.useEffect(() => {
    if (filter) {
      getOrdersExt({
        variables: {
          data: {
            filter,
            range: [0, perPage],
            index: 'created'
          }
        }
      })
    }
  }, [filter, getOrdersExt]);

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Dropdown placeholder="Payment Status" clearable options={paymentStatuses} selection value={status} onChange={(e, { value }) => setStatus(value)} />
          <SemanticDatepicker placeholder="Created From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="Created To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
        </Menu.Item>
      </Menu>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1">#</Table.HeaderCell>
            <Table.HeaderCell width="1">ID</Table.HeaderCell>
            <Table.HeaderCell width="1">Created</Table.HeaderCell>
            <Table.HeaderCell width="1">Customer</Table.HeaderCell>
            <Table.HeaderCell width="1">Amount</Table.HeaderCell>
            <Table.HeaderCell width="1">Shipping Address</Table.HeaderCell>
            <Table.HeaderCell width="1">Card Number</Table.HeaderCell>
            <Table.HeaderCell width="1">Card Updated</Table.HeaderCell>
            <Table.HeaderCell width="1">Payment Status</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listOrdersExt?.orders?.map((order, ind) => (
              <Table.Row key={order.id}>
                <Table.Cell>{ind + 1}/{data?.listOrdersExt?.total}</Table.Cell>
                <Table.Cell>{order.id}</Table.Cell>
                <Table.Cell>{order.wcCreated}</Table.Cell>
                <Table.Cell>{order.billing?.firstName} {order.billing?.lastName}</Table.Cell>
                <Table.Cell>{order.wcTotal}</Table.Cell>
                <Table.Cell>{[order.billing?.postcode, order.billing?.city, order.billing?.address1, order.billing?.address2, order.billing?.address1].join(', ')}</Table.Cell>
                <Table.Cell>{order.paymentCard?.lastFour}</Table.Cell>
                <Table.Cell>{JSON.stringify(order.paymentCard?.updatedCard)}</Table.Cell>
                <Table.Cell>{order.paymentStatus}</Table.Cell>
              </Table.Row>
            ))
          }
          {
            (loading || hasNextPage) && (
              <Table.Row>
                <Table.Cell>
                  <div ref={sentryRef}>Loading...</div>
                </Table.Cell>
              </Table.Row>
            )
          }

        </Table.Body>
      </Table>
    </PrivateRoute>
  )
}
