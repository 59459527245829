import { gql } from '@apollo/client';
import { client } from '../../../graphql_client';

const paperSizes = {
  default: {
    width: `8.5in`,
    height: `11in`,
  },
  defaultDummy: {
    width: `8.5in`,
    height: `11in`,
  },
}

const mutation = gql`mutation Printer_MyMutation($content: String!, $width: String!, $height: String!, $type: String!, $title: String!, $pageRanges: String!, $template: String!, $orderId: String, $customerName: String, $printTemplate: String) {
  printJobPPCreate(jobPP: {content: $content, title: $title, type: $type, width: $width, height: $height, pageRanges: $pageRanges, template: $template, orderId: $orderId, customerName: $customerName, printTemplate: $printTemplate}) {
    id
    errors {
      message
    }
  }
}`

export async function sendToPrintPDF(content, id = 'default', pages = 1, token = '', template = '', item) {
  const selectedPrinterType = localStorage.getItem('listSlipPrinter') ?? 'slip';
  const { data, errors } = await client.mutate({
    mutation,
    variables: {
      content,
      template,
      width: paperSizes.defaultDummy.width,
      height: paperSizes.defaultDummy.height,
      type: selectedPrinterType,
      title: `${id} - ${selectedPrinterType}`,
      pageRanges: `1-${pages}`,
      orderId: `${id}`,
      customerName: `${item?.customer || item?.to?.name}`,
      printTemplate: 'Print Slip'
    },
  });

  if (errors?.length) {
    throw errors[0]
  }

  return {
    errors: data?.printJobPPCreate.errors
  }
}
