import {guard} from 'effector-root'
import {sample} from 'effector-root'

import * as fromAuth from 'feature/auth'
import * as fromProducts from 'feature/orders'
import {contract} from 'lib/contract'

import * as page from './page'
import * as model from './model'
export {PageHome} from './page'

contract({
  page,
  model,
})

sample({
  source: model.onPageStarted,
  target: fromAuth.onRestoreSession,
})

sample({
  source: model.onSignOut,
  target: fromAuth.onSignOut,
})

sample({
  clock: model.onSubmit,
  source: {
    username: model.$username,
    password: model.$password,
  },
  target: [fromAuth.onSignIn, model.onFormClear],
})

sample({
  source: model.onQuery,
  target: fromProducts.onQuery,
})

sample({
  source: model.onCreate,
  target: fromProducts.onCreate,
})

sample({
  source: fromAuth.$currentRole,
  target: model.$currentRole,
})

sample({
  source: fromAuth.$currentUser,
  fn: (v) => v?.username ?? null,
  target: model.$currentUsername,
})

sample({
  source: model.onPageStarted,
  target: fromProducts.onCreateSubscription,
})

sample({
  source: fromProducts.$products,
  target: model.$products,
})
