import * as React from 'react';
import { createDomain } from 'effector-root';
import { useQuery, gql } from '@apollo/client';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header } from 'semantic-ui-react';

import PrivateRoute from 'ui/components/PrivateRoute';
import MainMenu from '../../components/MainMenu';

const domain = createDomain('Customers (Page)');
export const onPageStarted = domain.event();


const listCustomersQuery = gql`query QueryListCustomers {
  listCustomers{
    id
    name
    username
    email
    phone
    address {
      country
      state
      city
      zipcode
    }
  }
}`;


export function PageCustomers(props) {
  const { loading, error, data } = useQuery(listCustomersQuery);
  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>Username</Table.HeaderCell>
            <Table.HeaderCell>email</Table.HeaderCell>
            <Table.HeaderCell>phone</Table.HeaderCell>
            <Table.HeaderCell>Country</Table.HeaderCell>
            <Table.HeaderCell>State</Table.HeaderCell>
            <Table.HeaderCell>City</Table.HeaderCell>
            <Table.HeaderCell>Zip Code</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listCustomers?.map(customer => (
              <Table.Row key={customer.id}>
                <Table.Cell>{customer.name}</Table.Cell>
                <Table.Cell>{customer.username}</Table.Cell>
                <Table.Cell>{customer.email}</Table.Cell>
                <Table.Cell>{customer.phone}</Table.Cell>
                <Table.Cell>{customer.address?.country}</Table.Cell>
                <Table.Cell>{customer.address?.state}</Table.Cell>
                <Table.Cell>{customer.address?.city}</Table.Cell>
                <Table.Cell>{customer.address?.zipcode}</Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </PrivateRoute>
  )
}
