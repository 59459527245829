import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useLazyQuery, gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('PNP Logs (Page)');
export const onPageStarted = domain.event();

const listPnpLogsQuery = gql`
  query listPnpLogs($data: ListPnpLogsInput) {
    listPnpLogs(data: $data) {
      logs {
        id
        innerId
        user
        version
        start
        end
        duration
        operationName
        query
        variables
        data
        errors
        ok
        wcCreated
      }
      total
      range
    }
  }
`;


const perPage = 40;

export function PagePnpLogs(props) {
  const [innerId, setInnerId] = React.useState('');
  const [fromDate, setFromDate] = React.useState(sub(new Date(), { days: 1 }));
  const [toDate, setToDate] = React.useState(add(new Date(), { days: 1 }));
  const [selectedLog, setSelectedLog] = React.useState();

  const [filter, setFilter] = React.useState();

  React.useEffect(() => {
    if (fromDate && toDate) {
      setFilter({
        from: format(fromDate, "yyyy-MM-dd"),
        to: format(toDate, "yyyy-MM-dd")
      });
    } else {
      setFilter(null);
    }
  }, [fromDate, toDate]);

  const [listPnpLogs, { loading, error, data, fetchMore, refetch }] = useLazyQuery(listPnpLogsQuery, {
    errorPolicy: 'all'
  });


  const hasNextPage = React.useMemo(() => {
    return data?.listPnpLogs?.total > data?.listPnpLogs?.range[1];
  }, [data]);

  const loadMore = () => {
    const range = [data?.listPnpLogs?.range[0] + perPage, data?.listPnpLogs?.range[1] + perPage];
    fetchMore({
      variables: {
        data: {
          filter,
          range
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => (
        {
          listPnpLogs: {
            total: fetchMoreResult?.listPnpLogs?.total,
            range: fetchMoreResult?.listPnpLogs?.range,
            logs: [...prev.listPnpLogs.logs, ...fetchMoreResult.listPnpLogs.logs]
          }
        }
      )
    })
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });


  React.useEffect(() => {
    if (filter) {
      listPnpLogs({
        variables: {
          data: {
            filter,
            range: [0, perPage],
          }
        }
      })
    }
  }, [filter, listPnpLogs]);

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <SemanticDatepicker placeholder="Created From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="Created To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
        </Menu.Item>
      </Menu>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1">#</Table.HeaderCell>
            <Table.HeaderCell width="1">ID</Table.HeaderCell>
            <Table.HeaderCell width="1">Request Success</Table.HeaderCell>
            <Table.HeaderCell width="1">Operation Name</Table.HeaderCell>
            <Table.HeaderCell width="1">Request Duration</Table.HeaderCell>
            <Table.HeaderCell width="1">Created</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listPnpLogs?.logs?.map((log, ind) => (
              <Table.Row key={log.id}>
                <Table.Cell>{ind + 1}/{data?.listPnpLogs?.total}</Table.Cell>
                <Table.Cell onClick={() => setSelectedLog(log)}>{log.innerId}</Table.Cell>
                <Table.Cell>{JSON.stringify(log.ok)}</Table.Cell>
                <Table.Cell>{log.operationName}</Table.Cell>
                <Table.Cell>{log.duration}</Table.Cell>
                <Table.Cell>{log.wcCreated}</Table.Cell>
              </Table.Row>
            ))
          }
          {
            (loading || hasNextPage) && (
              <Table.Row>
                <Table.Cell>
                  <div ref={sentryRef}>Loading...</div>
                </Table.Cell>
              </Table.Row>
            )
          }

        </Table.Body>
      </Table>
      <Modal open={!!selectedLog} dimmer="blurring">
        <Modal.Header> Details #{selectedLog?.id}</Modal.Header>
        <Modal.Content scrolling>
          <div>
            <pre>
              {JSON.stringify(selectedLog, null, ' ')}
            </pre>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={() => setSelectedLog(undefined)} />
        </Modal.Actions>
      </Modal>
    </PrivateRoute>
  )
}
