import styled from 'styled-components'

const DEFAULT_COLOR = '#0a6629'

export const List = styled.ul`
  list-style: none;
  padding: 0;
`

export const Container = styled.div`
  display: block;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
`

export const Tr = styled.tr`
  cursor: pointer;
  transition: all 0.2s;
  background-color: ${(props) => (props.status ? '#cdf7ca' : 'transparent')};
  td {
    border-bottom: 1px solid #f0f0f0;
  }
`

export const Label = styled.label`
  min-width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Filters = styled.div`
  display: flex;
  padding: 10px 0;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: #fff;
`

export const Check = styled.input`
  cursor: pointer;
`

export const Picker = styled.div`
  display: inline-flex;
  position: relative;
  input {
    box-sizing: border-box;
    margin: 0;
    color: rgba(0, 0, 0, 0.85);
    font-size: 14px;
    position: relative;
    display: inline-flex;
    align-items: center;
    background: #fff;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: border 0.3s, box-shadow 0.3s;
    height: auto;
    left: 0;
    right: 0;
    top: 0;
    width: auto;
  }

  input::-webkit-calendar-picker-indicator {
    background: transparent;
    bottom: 0;
    color: transparent;
    cursor: pointer;
    height: auto;
    left: 0;
    position: absolute;
    right: 0;
    top: 0;
    width: auto;
    padding: 0;
    margin: 0;
  }
`

export const Table = styled.table`
  overflow: hidden;
  width: 100%;
  margin: 10px 0;
  background-color: #f5f6f7;
  border: 1px solid #e1e3e6;
  border-radius: 8px;
  border-spacing: 0;
  th,
  td {
    text-align: left;
    font-size: 14px;
    padding: 16px;
  }
`

export const Input = styled.input`
  padding: 0.5em;
  background: papayawhip;
  border: none;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.5em;
`

export const Wrapper = styled.div`
  display: block;
  width: 100%;
  height: 100vh;
  background-color: #fff;
`

export const Thead = styled.thead`
  th {
    font-weight: 600;
  }
`

export const Tbody = styled.tbody`
  background-color: #fff;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  padding: 17.5px 24px;
  color: #fff;
  height: 61px;
  background-color: ${DEFAULT_COLOR};
  border-radius: 8px;
  border: none;
  cursor: pointer;
  font-weight: 500;
  font-size: 14px;
  line-height: 1.25;
  margin: 0 5px;
  font-family: 'Sora', PT Sans, sans-serif;
  &:focus {
    outline: 0;
  }
  &:hover {
    background-color: #34a34f;
  }
`

export const Loader = styled.div`
  border-radius: 50%;
  width: 1.5em;
  height: 1.5em;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation: load7 1.8s infinite ease-in-out;
  animation: load7 1.8s infinite ease-in-out;
  &:before,
  &:after {
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    -webkit-animation: load7 1.8s infinite ease-in-out;
    animation: load7 1.8s infinite ease-in-out;
  }

  color: ${DEFAULT_COLOR};
  font-size: 10px;
  margin: 80px auto;
  position: relative;
  text-indent: -9999em;
  -webkit-transform: translateZ(0);
  -ms-transform: translateZ(0);
  transform: translateZ(0);
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
  }
  &:before {
    left: -3.5em;
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  &:after {
    left: 3.5em;
  }
  @-webkit-keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
  @keyframes load7 {
    0%,
    80%,
    100% {
      box-shadow: 0 2.5em 0 -1.3em;
    }
    40% {
      box-shadow: 0 2.5em 0 0;
    }
  }
`

export const CircleLoader = styled.div`
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  padding: 1px;
  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: 25px;
    height: 25px;

    border: 3px solid #fff;
    border-radius: 50%;
    animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #fff transparent transparent transparent;
  }
  div:nth-child(1) {
    animation-delay: -0.45s;
  }
  div:nth-child(2) {
    animation-delay: -0.3s;
  }
  div:nth-child(3) {
    animation-delay: -0.15s;
  }
  @keyframes lds-ring {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`
