import { Auth, withSSRContext } from 'aws-amplify'

import { createDomain } from 'effector-root'
import { guard } from 'effector-root'
import { sample } from 'effector-root'

const domain = createDomain(`Auth (Feature)`)

const ERRORS = {
  NOT_AUTHENTICATED: `NOT_AUTHENTICATED`,
}

const CHALLENGES = {
  NEW_PASSWORD_REQUIRED: `NEW_PASSWORD_REQUIRED`,
}

export const ROLES = {
  GUEST: `GUEST`,
  USER: `USER`,
}

export const onSignIn = domain.event()
export const onSignOut = domain.event()
export const onRestoreSession = domain.event()
export const onRestoreServerSession = domain.event()
export const $authState = domain.store(0);
export const $currentUser = domain.store(null)
export const $token = $currentUser.map(
  (v) => v?.signInUserSession?.idToken?.jwtToken || null,
)
export const $currentRole = $currentUser.map((v) =>
  v ? ROLES.USER : ROLES.GUEST,
)

export const fxRestoreSession = domain.effect({
  async handler() {
    const user = await Auth.currentAuthenticatedUser();
    return user;

  },
})

$currentUser.on(fxRestoreSession.doneData, (_, v) => v)
$authState.on(fxRestoreSession.doneData, authStateSet);
$authState.on(fxRestoreSession.fail, () => 2);

export const fxRestoreServerSession = domain.effect({
  async handler({ req }) {
    const fromAmplify = withSSRContext({ req })
    const user = await fromAmplify.Auth.currentAuthenticatedUser();
    return user;
  },
})

$currentUser.on(fxRestoreServerSession.doneData, (_, v) => v);
$authState.on(fxRestoreServerSession.doneData, authStateSet);
$authState.on(fxRestoreServerSession.fail, () => 2);


export const fxSignOut = domain.effect({
  handler() {
    return Auth.signOut()
  },
})

$currentUser.on(fxSignOut.done, () => null)
$authState.on(fxSignOut.done, () => 2);

export const fxSignIn = domain.effect({
  async handler({ username, password }) {
    let user = await Auth.signIn({ username, password })

    if (user) {
      if (user.challengeName === CHALLENGES.NEW_PASSWORD_REQUIRED) {
        // const { phone_number, email } = user.challengeParam.userAttributes
        // user = await Auth.completeNewPassword(user, password, {
        //   phone_number,
        //   email,
        // })
        user = await Auth.completeNewPassword(user, password);
      }
      return user
    }

    throw Error(ERRORS.NOT_AUTHENTICATED)
  },
})

$currentUser.on(fxSignIn.doneData, (_, v) => v);
$authState.on(fxSignIn.done, authStateSet);
$authState.on(fxSignIn.fail, () => 2);

sample({
  source: onRestoreSession,
  target: fxRestoreSession,
})

guard({
  source: onSignOut,
  filter: $currentUser,
  target: fxSignOut,
})

sample({
  source: onSignIn,
  target: fxSignIn,
})

sample({
  source: onRestoreServerSession,
  target: fxRestoreServerSession,
})

function authStateSet(_, v) {
  return v ? 1 : 2
}
