import 'semantic-ui-css/semantic.min.css';
import 'react-semantic-ui-datepickers/dist/react-semantic-ui-datepickers.css';
import './index.css'

import { attachLogger } from 'logger/attach'
import { fork, hydrate } from 'effector/fork'
import { root } from 'effector-root'

import { BrowserRouter } from 'react-router-dom'
import * as React from 'react'
import * as ReactDOM from 'react-dom'

import { App } from './app'

const scope = fork(root)
hydrate(scope, { values: INITIAL_STATE })
attachLogger(scope, {
  console: `disabled`,
  inspector: `disabled`,
})

ReactDOM.hydrate(
  <BrowserRouter>
    <App scope={scope} />
  </BrowserRouter>,
  document.getElementById(`root`),
)

if (module.hot) {
  module.hot.accept()
}
