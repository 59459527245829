import {createDomain} from 'effector-root'

import {ROLES} from 'feature/auth/model'

const domain = createDomain(`Home (Model)`)

export const onPageStarted = domain.event()
export const onUsernameChange = domain.event()
export const onPasswordChange = domain.event()
export const onSignOut = domain.event()
export const onSubmit = domain.event()
export const onQuery = domain.event()
export const onCreate = domain.event()

export const $currentRole = domain.store(ROLES.GUEST)
export const $currentUsername = domain.store(null)

const formDomain = domain.domain(`form`)

formDomain.onCreateStore(($store) => {
  $store.reset(onPageStarted)
  $store.reset(onFormClear)
})

export const onFormClear = formDomain.event()
export const $username = formDomain.createStore(``)
export const $password = formDomain.createStore(``)
export const $products = domain.store([])

$username.on(onUsernameChange, (_, v) => v)
$password.on(onPasswordChange, (_, v) => v)
