// https://github.com/accesso-app/frontend/commit/719443c7e4e1c4fc628ea48f6330cee857c816d3
import {is, forward} from 'effector'

export function contract(config) {
  for (const name in config.page) {
    const pageUnit = config.page[name]
    const modelUnit = config.model[name]

    if (pageUnit && modelUnit && is.unit(pageUnit) && is.unit(modelUnit)) {
      if (is.store(pageUnit) && is.store(modelUnit)) {
        forward({from: modelUnit, to: pageUnit})
        pageUnit.defaultState = modelUnit.defaultState
        pageUnit.stateRef.current = modelUnit.stateRef.current
        pageUnit.stateRef.before = [
          {
            type: 'map',
            fn: (state) => state,
            from: modelUnit.stateRef,
          },
        ]
      } else {
        forward({from: pageUnit, to: modelUnit})
      }
    }
  }
}
