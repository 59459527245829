import * as React from 'react';
import ReCAPTCHA from "react-google-recaptcha";
import { Auth } from 'aws-amplify';
import AWSAppSyncClient, { AUTH_TYPE } from 'aws-appsync';
import { gql } from '@apollo/client';
import { createDomain } from 'effector-root';
import { Segment, Header, Form } from 'semantic-ui-react';
import { Link } from 'react-router-dom';

const CONFIG = require(`/core${process.env.NODE_ENV === 'development' ? '.local' : ''}.json`)

const REGION =
  process.env.RAZZLE_COGNITO_REGION || CONFIG.LCCore.ProjectRegion

const API_URL = process.env.API_URL || CONFIG.LCCore.GraphQLAPIURL

const domain = createDomain('ForgotPassword (Page)');
export const onPageStarted = domain.event();


const captchaQuery = gql`query CaptchaQuery($token: String!){
  captcha(token: $token) {
    success
    hostname
    errorCodes
    challenge_ts
  }
}`;

const client = new AWSAppSyncClient({
  url: API_URL,
  region: REGION,
  auth: {
    type: AUTH_TYPE.AWS_IAM,
    credentials: () => Auth.currentCredentials()
  },
  disableOffline: true
})

export function PageForgotPassword(props) {
  const [challengeState, setChallengeState] = React.useState('showCaptcha');
  const [userName, setUserName] = React.useState('');
  const [confirmCode, setConfirmCode] = React.useState('');
  const [newPassword, setNewPassword] = React.useState('');
  const [responseInfo, setResponseInfo] = React.useState('');
  const [responseError, setResponseError] = React.useState();
  const [loading, setLoading] = React.useState(false);

  const handleCaptchaChange = (value) => {
    const checkCaptcha = async () => {
      setLoading(true);
      const { data, errors } = await client.query({
        query: captchaQuery,
        variables: {
          token: value
        }
      });
      setLoading(false);
      if (data?.captcha?.success) {
        setChallengeState('showUserName');
      } else {
        setResponseError(JSON.stringify(errors ?? data?.captcha?.errorCodes))
      }
    }
    if (value) {
      checkCaptcha();
    }
  }

  const handleRequerstConfirmationCode = () => {
    setLoading(true);
    Auth.forgotPassword(userName).then((data) => {
      setResponseInfo(`Confirmation code sent to ${data?.CodeDeliveryDetails?.AttributeName} ${data?.CodeDeliveryDetails?.Destination}`)
      setResponseError(null)
      setChallengeState('showConfirmCode');
    }).catch((err) => {
      setResponseError(err?.message)
    }).finally(() => {
      setLoading(false);
    })
  }

  const handleResetPassword = () => {
    setLoading(true);
    Auth.forgotPasswordSubmit(userName, confirmCode, newPassword).then((data) => {
      setResponseInfo(data)
      setResponseError(null)
      setChallengeState('showSuccess');
    }).catch((err) => {
      setResponseError(err?.message)
    }).finally(() => {
      setLoading(false);
    })
  }

  return (
    <Segment padded style={{ margin: '40px auto', minWidth: 300 }} compact loading={loading}>
      <Header>Reset Password</Header>
      {
        challengeState === 'showCaptcha' && (
          <Form>
            <ReCAPTCHA sitekey="6LfEYL8bAAAAAF0d57QwFmJ74PhBMqZfpfwn0GEw" onChange={handleCaptchaChange} />
          </Form>
        )
      }
      {
        challengeState === 'showUserName' && (
          <Form>
            <Form.Input value={userName} onChange={(e, { value }) => setUserName(value)} label="User Name" />
            <Form.Button content="Request Confirmation Code" type="button" disabled={!userName} onClick={handleRequerstConfirmationCode} />
          </Form>
        )
      }
      {
        challengeState === 'showConfirmCode' && (
          <Form>
            <Form.Input value={confirmCode} onChange={(e, { value }) => setConfirmCode(value)} label="Confirmation Code" />
            <Form.Input value={newPassword} onChange={(e, { value }) => setNewPassword(value)} label="New Password" type="password" />
            <Form.Button content="Reset Password" type="button" disabled={!confirmCode || !newPassword} onClick={handleResetPassword} />
          </Form>
        )
      }
      {
        challengeState === 'showSuccess' && (
          <div>
            <div>Password Changed</div>
            <Link to="/">Go to Login</Link>
          </div>
        )
      }
      {
        responseInfo && <div>{JSON.stringify(responseInfo)}</div>
      }
      {
        responseError && <div style={{ color: 'red' }}>{JSON.stringify(responseError)}</div>
      }
    </Segment>
  )
}
