import './amplify'
import { Provider } from 'effector-react/ssr'
import * as React from 'react'
import { Route, Switch } from 'react-router-dom'
import { GraphqlWrapper } from './graphql_client';
import { PageHome } from 'ui/page/home'
import { PageListSlip } from 'ui/page/page-list-slip'
import { PagePrintMap } from 'ui/page/print_map';
import { PageCustomers } from 'ui/page/customers';
import { PageOrders } from 'ui/page/orders';
import { PageOrdersReport } from 'ui/page/orders-report';
import { PagePrintMode } from 'ui/page/print-mode';
import { PageProducts } from 'ui/page/products';
import { PageWcSync } from 'ui/page/wc-sync';
import { PrintFiles } from 'ui/page/print-files';
import { PageShippingRoutes } from 'ui/page/shipping-routes';
import { PageUsers } from 'ui/page/users';
import { PageForgotPassword } from 'ui/page/forgot';
import { PagePaymentReport } from 'ui/page/payment-report';
import { PageOrdersBulkAction } from 'ui/page/orders-bulk-action/page';
import { PagePnpLogs } from 'ui/page/pnp_logs';
import { PageAssemblyList } from 'ui/page/assembly-list';
import { PageBreakdownList } from 'ui/page/breakdown-list';
import { PageBreakdownCustomerOrder } from 'ui/page/breakdown-co';
import { PageReview } from 'ui/page/review/page';

export function App({ scope }) {
  return (
    <Provider value={scope}>
      <GraphqlWrapper>
        <Switch>
          <Route exact path="/list-slip" component={PageListSlip} />
          <Route exact path="/print-map" component={PagePrintMap} />
          <Route exact path="/customers" component={PageCustomers} />
          <Route exact path="/orders" component={PageOrders} />
          <Route exact path="/orders-report" component={PageOrdersReport} />
          <Route exact path="/print-mode" component={PagePrintMode} />
          <Route exact path="/products" component={PageProducts} />
          <Route exact path="/wc-sync" component={PageWcSync} />
          <Route exact path="/print-files" component={PrintFiles} />
          <Route exact path="/shipping-routes" component={PageShippingRoutes} />
          <Route exact path="/users" component={PageUsers} />
          <Route exact path="/forgot" component={PageForgotPassword} />
          <Route exact path="/payment-report" component={PagePaymentReport} />
          <Route exact path="/orders-bulk-action" component={PageOrdersBulkAction} />
          <Route exact path="/pnp-logs" component={PagePnpLogs} />
          <Route exact path="/assembly-list" component={PageAssemblyList} />
          <Route exact path="/breakdown-list" component={PageBreakdownList} />
          <Route exact path="/breakdown-co" component={PageBreakdownCustomerOrder} />
          <Route exact path="/review" component={PageReview} />
          <Route exact path="/" component={PageHome} />
        </Switch>
      </GraphqlWrapper>
    </Provider>
  )
}
