import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox, TableCell } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { gql, useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('Breakdown List (Page)');
export const onPageStarted = domain.event();


const listBreakdownListQuery = gql`
  query QueryBreakdownList($data: ListOrdersFilterExt) {
    breakdownList(data: $data) {
      mealsNum
      ordersNum
    }
  }
`;

const orderStatuses = ['processing', 'picked', 'packed', 'shipping', 'shipped', 'hold', 'failed', 'delivered'];
const availableStatuses = orderStatuses.map(s => ({ id: s, text: s, value: s }));

export function PageBreakdownList(props) {
  const [fromDate, setFromDate] = React.useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [toDate, setToDate] = React.useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
  const [loading, setLoading] = React.useState(null);
  const [coreData, setCoreData] = React.useState();
  const client = useApolloClient();
  const [updatedAt, setUpdatedAt] = React.useState();
  const [statuses, setStatuses] = React.useState(['processing']);


  const handleMakeReport = async () => {
    const requestData = () => {
      return client.query({
        query: listBreakdownListQuery, variables: {
          data: {
            from: format(fromDate, "yyyy-MM-dd"),
            to: format(toDate, "yyyy-MM-dd"),
            statuses
          }
        }
      })
    }
    setLoading('Loading data ');
    const responseCore = await requestData();
    // console.log('---make report core---', responseCore.data);
    setCoreData(responseCore.data?.breakdownList);
    setLoading(null);
    setUpdatedAt(new Date());
  }

  const totalOrders = React.useMemo(() => {
    if (coreData) {
      return coreData.reduce((m, d) => m + d.ordersNum, 0)
    }
    return 0;
  }, [coreData]);

  const NumOfBoxes = React.useCallback((mealsNum) => {
    if (mealsNum === 0) {
      return 0;
    }
    if (mealsNum >= 18) {
      return 3;
    }
    return mealsNum >= 10 ? 2 : 1;
  }, []);

  const NumOfIcePacks = React.useCallback((mealsNum) => {
    return 2 * NumOfBoxes(mealsNum);
  }, [NumOfBoxes]);

  const NumOfInsulation = React.useCallback((mealsNum) => {
    return 1 * NumOfBoxes(mealsNum);
  }, [NumOfBoxes]);

  const totalBoxes = React.useMemo(() => {
    if (coreData) {
      return coreData.reduce((m, d) => m + d.ordersNum, 0)
    }
    return 0;
  }, [coreData]);

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={!!loading} inverted>
        <Loader>{loading}</Loader>
      </Dimmer>
      <Menu compact>
        <Menu.Item>
          <Dropdown placeholder="Status" multiple options={availableStatuses} selection value={statuses} onChange={(e, { value }) => setStatuses(value)} />
          <SemanticDatepicker placeholder="From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
          <Button content="Load Data" onClick={handleMakeReport} />
        </Menu.Item>
      </Menu>
      {coreData &&
        <div style={{ margin: 15 }}>
          {
            updatedAt && <div>Data Loaded: {JSON.stringify(updatedAt)}</div>
          }
          <br />

          <div>Types of Order:</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {order.ordersNum}</div>)
          }
          <div>Total number of orders: {totalOrders}</div>

          <br />
          <div>Projection of boxes:</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {NumOfBoxes(order.mealsNum) * order.ordersNum}</div>)
          }
          <div>Total number of boxes: {coreData?.reduce((m, order) => m + NumOfBoxes(order.mealsNum) * order.ordersNum, 0)}</div>
          <br />
          <div>Projection of ice packs:</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {NumOfIcePacks(order.mealsNum) * order.ordersNum}</div>)
          }
          <div>Total number of ice packs: {coreData?.reduce((m, order) => m + NumOfIcePacks(order.mealsNum) * order.ordersNum, 0)}</div>
          <br />
          <div>Projection of single insulation:</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {NumOfInsulation(order.mealsNum) * order.ordersNum}</div>)
          }
          <div>Total number of insulation: {coreData?.reduce((m, order) => m + NumOfInsulation(order.mealsNum) * order.ordersNum, 0)}</div>
          <br />
          <div>Projection of double insulation:</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {NumOfInsulation(order.mealsNum) * order.ordersNum * 2}</div>)
          }
          <div>Total number of insulation: {coreData?.reduce((m, order) => m + NumOfInsulation(order.mealsNum) * order.ordersNum * 2, 0)}</div>
          <br />
          <div>Projection of Stacks/Sugar can trays (Meals):</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {order.ordersNum * order.mealsNum}</div>)
          }
          <div>Total number of sugar cane trays: {coreData?.reduce((m, order) => m + order.ordersNum * order.mealsNum, 0)}</div>
          <br />
          <div>Projection of Components:</div>
          {
            coreData?.map(order => <div key={`${order.mealsNum}`}>{order.mealsNum} meals = {order.ordersNum * order.mealsNum * 3}</div>)
          }
          <div>Total number of Components: {coreData?.reduce((m, order) => m + order.ordersNum * order.mealsNum * 3, 0)}</div>
        </div>
      }
      {/* <div>
        <pre>{JSON.stringify(coreData)}</pre>
      </div> */}
    </PrivateRoute>
  )
}

