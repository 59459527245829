import * as React from 'react'
import { createDomain } from 'effector-root'
import { useEvent } from 'effector-react/ssr'
import { reflect, variant } from '@effector/reflect/ssr'
import { Checkbox } from 'semantic-ui-react'

import PrivateRoute from 'ui/components/PrivateRoute';
import MainMenu from '../../components/MainMenu';

import * as A from './styled'

const domain = createDomain(`Slip (Page)`)
export const onPageStarted = domain.event()
export const onQuery = domain.event()
export const onDateStartChange = domain.event()
export const onDateEndChange = domain.event()
export const onSet = domain.event()
export const onReset = domain.event()
export const onPrint = domain.event()

export const $products = domain.store([])
export const $activeMap = domain.store([])
export const $pending = domain.store(true)
export const $printPending = domain.store(false)

import SelectPrinter from './SelectPrinter';

const getDate = (n) =>
  new Date(new Date().setDate(new Date().getDate() + n))
    .toISOString()
    .split('T')[0]

export const $dateStart = domain.store(getDate(-1))
export const $dateEnd = domain.store(getDate(1))

export function PageListSlip(props) {
  useMount(props)

  return (
    <A.Wrapper>
      <PrivateRoute>
        <MainMenu />
        <A.Container>
          <A.Filters>
            <DateStart />
            <DateEnd />
            <SelectPrinter />
            <Print />
            <Query />
          </A.Filters>
          <Slips />
        </A.Container>
      </PrivateRoute>
    </A.Wrapper>
  )
}
function useMount(props) {
  const onMount = useEvent(onPageStarted)

  React.useEffect(() => {
    onMount(props)
  }, [])
}

const Products = reflect({
  view: List,
  bind: {
    products: $products,
    activeMap: $activeMap,
    onSet: onSet,
  },
})

const AllCheckboxes = reflect({
  view: AllCheckboxesView,
  bind: {
    products: $products,
    activeMap: $activeMap,
    onSet: onSet,
    onReset: onReset
  }
})

function AllCheckboxesView({ products, activeMap, onSet, onReset }) {
  let checked = true;
  if (products.length === Object.keys(activeMap).length) {
    checked = false;
  }

  const handleCheck = (e) => {
    if (!checked) {
      onReset();
    } else {
      onSet(products.filter(p => !activeMap[p.id]).map(p => p.id));
    }
  };

  return <th width={'10%'}><Checkbox checked={checked} onChange={handleCheck} /></th>
}

function Pending() {
  return <A.Loader></A.Loader>
}

function PrintPending() {
  return (
    <A.Button>
      <A.CircleLoader>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </A.CircleLoader>
    </A.Button>
  )
}

function Table() {
  return (
    <A.Table>
      <A.Thead>
        <tr>
          <AllCheckboxes />
          <th width={'10%'}>Id</th>
          <th width={'20%'}>Name</th>
          <th width={'10%'}>Meals</th>
          <th>Shipping Address</th>
          <th>Customer Name</th>
        </tr>
      </A.Thead>
      <Products />
    </A.Table>
  )
}

const Slips = variant({
  source: $pending,
  cases: {
    true: Pending,
  },
  default: Table,
})

const Print = variant({
  source: $printPending,
  cases: {
    true: PrintPending,
  },
  default: Button,
  bind: {
    label: `Print`,
    onClick: onPrint.prepend(noop),
  },
})

// const Print = reflect({
//   view: Button,
//   bind: {
//     label: `Print`,
//     onClick: onPrint.prepend(noop),
//   },
// })

const Query = reflect({
  view: Button,
  bind: {
    label: `Query`,
    onClick: onQuery.prepend(noop),
  },
})

function Button({ label, type = `button`, onClick }) {
  return (
    <A.Button type={type} onClick={onClick}>
      {label}
    </A.Button>
  )
}

function ItemCheckbox({ onChange, value }) {
  return (
    <A.Check type={`checkbox`} onChange={onChange} checked={value} />
  )
}

function List({ products, activeMap, onSet }) {
  return (
    <A.Tbody>
      {products.map(({ id, name, meals, to: { street1, city, state, zip, name: customerName } }) => (
        <A.Tr key={id} status={!activeMap[id]}>
          <td width={'10%'}>
            <ItemCheckbox
              id={id}
              value={!activeMap[id]}
              onChange={() => onSet(id)}
            />
          </td>
          <td width={'10%'}>{id}</td>
          <td width={'20%'}>{name}</td>
          <td width={'10%'}>{meals.length}</td>
          <td>
            {street1}
            <br />
            {`${city}, ${state}, ${zip}`}
          </td>
          <td>
            {customerName}
          </td>
        </A.Tr>
      ))}
    </A.Tbody>
  )
}

const DateStart = reflect({
  view: Input,
  bind: {
    label: `Date Start`,
    type: `date`,
    value: $dateStart,
    onChange: onDateStartChange.prepend(getValue),
  },
})

const DateEnd = reflect({
  view: Input,
  bind: {
    label: `Date End`,
    type: `date`,
    value: $dateEnd,
    onChange: onDateEndChange.prepend(getValue),
  },
})

function Input({ label = ``, type, value, onChange }) {
  return (
    <A.Picker>
      <A.Label htmlFor={label}>{label}</A.Label>
      <A.Input type={type} value={value} id={label} onChange={onChange} />
    </A.Picker>
  )
}

function getValue(e) {
  return e.currentTarget.value
}

function noop() { }
