import * as React from 'react';
import { createDomain } from 'effector-root';
import { Segment, Button, TransitionablePortal, Header } from 'semantic-ui-react';
import { useLazyQuery, gql } from '@apollo/client';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('WcSync (Page)');
export const onPageStarted = domain.event();


const syncProductsQuery = gql`query SyncProducts($data: SyncProductsInput){
  syncProducts(data: $data)
}`;


export function PageWcSync(props) {
  const [syncProducts, { loading: loading, error }] = useLazyQuery(syncProductsQuery);
  const [state, setState] = React.useState({ meals: false, groceries: false, vitamins: false, package: false, components: false });
  return (
    <PrivateRoute>
      <MainMenu />
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <div style={{ padding: '0 15px', overflowX: 'auto' }}>
        <Segment style={{ textAlign: 'center' }}>
          <Button
            size='massive'
            onClick={() => {
              syncProducts({ variables: { data: { components: true } } });
              setState(s => ({ ...s, components: true }))
            }}
          >
            {state.components ? 'Syncing' : 'Sync'} Components
          </Button>
        </Segment>
        <Segment style={{ textAlign: 'center' }}>
          <Button
            size='massive'
            onClick={() => {
              syncProducts({ variables: { data: { meals: true } } });
              setState(s => ({ ...s, meals: true }))
            }}
            disabled
          >
            {state.meals ? 'Syncing' : 'Sync'} Meals
          </Button>
        </Segment>
        <Segment style={{ textAlign: 'center' }}>
          <Button
            size='massive'
            onClick={() => {
              syncProducts({ variables: { data: { groceries: true } } });
              setState(s => ({ ...s, groceries: true }))
            }}
            disabled
          >
            {state.groceries ? 'Syncing' : 'Sync'} Groceries
          </Button>
        </Segment>
        <Segment style={{ textAlign: 'center' }}>
          <Button
            size='massive'
            onClick={() => {
              syncProducts({ variables: { data: { vitamins: true } } });
              setState(s => ({ ...s, vitamins: true }))
            }}
          >
            {state.vitamins ? 'Syncing' : 'Sync'} Vitamins
          </Button>
        </Segment>
        <Segment style={{ textAlign: 'center' }}>
          <Button
            size='massive'
            onClick={() => {
              syncProducts({ variables: { data: { packing: true } } });
              setState(s => ({ ...s, packing: true }))
            }}
          >
            {state.packing ? 'Syncing' : 'Sync'} Packing
          </Button>
        </Segment>
      </div>
    </PrivateRoute>
  )
}
