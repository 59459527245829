import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Button, Dropdown } from 'semantic-ui-react';
import { useLazyQuery, gql, useMutation, useQuery } from '@apollo/client';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('Shipping Routes (Page)');
export const onPageStarted = domain.event();


const listRoutesQuery = gql`query QueryListShippingRoutes{
  listShippingRoutes{
    routes {
      id
      date
      env
      team
      routeName
      routeId
    }
  }
}`;

const listDrivers = gql`query QueryListDrivers{
  listRoute4MeDrivers{
    member_id
    member_first_name
    member_last_name
  }
}`;

const listVehicles = gql`query QueryListDrivers{
  listRoute4MeVehicles{
    vehicle_id
    member_id
    vehicle_alias
  }
}`;


const triggerShippingService = gql`
  mutation triggerShippingService {
    triggerShippingService
  }
`;

const getSettingQuery = gql`query QuerySetting($id: ID!){
  getSetting(id: $id) {
    id
    value
  }
}`;

const updateSettingMutation = gql`mutation UpdateSetting($id: ID!, $data: SettingInput){
  updateSetting(id: $id, data: $data) {
    id
    value
  }
}`;

export function PageShippingRoutes(props) {
  const [getRoutes, { loading, error, data, refetch }] = useLazyQuery(listRoutesQuery, { errorPolicy: 'all' });
  const [triggerShip] = useMutation(triggerShippingService);
  React.useEffect(() => {
    getRoutes();
  }, [getRoutes]);

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Button onClick={() => triggerShip()}>Trigger Shipping Service</Button>
        </Menu.Item>
        <Menu.Item>
          <Button onClick={() => refetch()}>Refetch</Button>
        </Menu.Item>
        <Menu.Item>
          <Route4meDriver />
        </Menu.Item>
        <Menu.Item>
          <Route4meVehicle />
        </Menu.Item>
      </Menu>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Date</Table.HeaderCell>
            <Table.HeaderCell>Env</Table.HeaderCell>
            <Table.HeaderCell>Team</Table.HeaderCell>
            <Table.HeaderCell>Route Name</Table.HeaderCell>
            <Table.HeaderCell>Route Id</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listShippingRoutes?.routes?.map((route, ind) => (
              <Table.Row key={route.id}>
                <Table.Cell>{ind + 1}</Table.Cell>
                <Table.Cell>{route.id}</Table.Cell>
                <Table.Cell>{route?.date}</Table.Cell>
                <Table.Cell>{route?.env}</Table.Cell>
                <Table.Cell>{route?.team}</Table.Cell>
                <Table.Cell>{route?.routeName}</Table.Cell>
                <Table.Cell>{route?.routeId}</Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </PrivateRoute>
  )
}

function Route4meDriver(props) {
  const [route4meDriver, setRoute4meDriver] = React.useState();
  const { data: listDriversData, loading: listDriversLoading, error: listDriversError } = useQuery(listDrivers);
  const { data: getSettingQueryData, loading: getSettingQueryLoading, error: getSettingQueryError } = useQuery(getSettingQuery, { variables: { id: 'route4meDriver' } });
  const [updateSetting, { loading: settingLoading, error: settingLoadingError }] = useMutation(updateSettingMutation);

  const listDriversOptions = React.useMemo(() => {
    return listDriversData?.listRoute4MeDrivers?.map(d => ({ id: d?.member_id, value: d?.member_id, text: `${d?.member_first_name} ${d?.member_last_name}` })) ?? [];
  }, [listDriversData]);

  React.useEffect(() => {
    setRoute4meDriver(getSettingQueryData?.getSetting?.value);
  }, [getSettingQueryData])

  const handleDriverChange = (e, { value }) => {
    updateSetting({ variables: { id: 'route4meDriver', data: { value } } }).then(() => setRoute4meDriver(value));
  }

  return (
    <Dropdown
      placeholder="route4me driver"
      clearable
      options={listDriversOptions}
      selection
      value={route4meDriver}
      onChange={handleDriverChange}
      loading={listDriversLoading}
    />
  )
}

function Route4meVehicle(props) {
  const [route4meVehicle, setRoute4meVehicle] = React.useState();
  const { data: listVehiclesData, loading: listVehiclesLoading, error: listVehiclesError } = useQuery(listVehicles);
  const { data: getSettingQueryData, loading: getSettingQueryLoading, error: getSettingQueryError } = useQuery(getSettingQuery, { variables: { id: 'route4meVehicle' } });
  const [updateSetting, { loading: settingLoading, error: settingLoadingError }] = useMutation(updateSettingMutation);

  const listDriversOptions = React.useMemo(() => {
    return listVehiclesData?.listRoute4MeVehicles?.map(d => ({ id: d?.vehicle_id, value: d?.vehicle_id, text: d?.vehicle_alias })) ?? [];
  }, [listVehiclesData]);

  React.useEffect(() => {
    setRoute4meVehicle(getSettingQueryData?.getSetting?.value);
  }, [getSettingQueryData])

  const handleDriverChange = (e, { value }) => {
    updateSetting({ variables: { id: 'route4meVehicle', data: { value } } }).then(() => setRoute4meVehicle(value));
  }

  return (
    <Dropdown
      placeholder="route4me vehicle"
      clearable
      options={listDriversOptions}
      selection
      value={route4meVehicle}
      onChange={handleDriverChange}
      loading={listVehiclesLoading}
    />
  )
}