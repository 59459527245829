import { scopeBind, attach } from 'effector'
import { createDomain } from 'effector-root'
import { sample } from 'effector-root'

import { createProduct } from './api/create'
import { sendToPrintPDF } from './api/print'

// import {listProducts} from './api/list'
import { listOrders } from './api/list-orders'
import { createSubscription } from './api/createSubscription'
import { $token } from 'feature/auth/model'

const domain = createDomain(`Products (Feature)`)

export const onQuery = domain.event()
export const onCreate = domain.event()
export const onCreated = domain.event()
export const onPrint = domain.event()
export const onCreateSubscription = domain.event()

export const $products = domain.store([])

export const fxQuery = domain.effect({
  async handler(res) {
    const { token, dateStart, dateEnd } = res
    console.log('RES fxQUERY', res)
    return listOrders({ token, dateStart, dateEnd })
  },
})

$products.on(fxQuery.doneData, (_, v) => v)

export const fxCreate = domain.effect({
  async handler({ token }) {
    return createProduct({ token })
  },
})

export const fxCreateSubscription = domain.effect({
  async handler() {
    return createSubscription({
      handler: scopeBind(onCreated),
    })
  },
})

$products.on(onCreated, (_, v) => [v, ..._])

sample({
  source: onQuery,
  target: fxQuery,
})

sample({
  source: onCreateSubscription,
  target: fxCreateSubscription,
})

export const onReset = domain.event()
export const onSet = domain.event()
export const $activeMap = domain.store({})

// $activeMap.on(onSet, (prevState, id) => { 
//   const { [id]: current, ...state } = prevState
//   return current ? state : { ...state, [id]: true }
// })

$activeMap.on(onSet, (state, id) => {
  if (Array.isArray(id)) {
    const ret = id.reduce((m, item) => {
      const { [item]: current, ...other } = m;
      return current ? other : { ...other, [item]: true }
    }, { ...state });
    return ret;
  }
  const { [id]: current, ...other } = state
  return current ? other : { ...other, [id]: true }
})

$activeMap.on(onReset, () => {
  return {};
})

export const fxPrintSingle = domain.effect({
  async handler({ item, token }) {
    if (item.meals.length) {
      await sendToPrintPDF(JSON.stringify(item), item.id, 1, token, 'WCSlipMeals', item)
    }

    if (!(item.staples.length === 0 && item.vitamins.length === 0)) {
      await sendToPrintPDF(JSON.stringify(item), item.id, 9, token, 'WCSlipGroceriesAndVitamins', item)
    }

    return true
  },
})

fxPrintSingle.fail.watch(({ params, error }) => {
  console.log(params, error)
})

fxPrintSingle.doneData.watch(({ params, error }) => { })

export const _fxPrint = domain.effect({
  async handler({ activeMap, products, token }) {
    const list = products.filter(({ id }) => !activeMap[id])
    // const result = await Promise.allSettled(
    //   list.map(async (slip) => {
    //     // addTask(await componentSlip(slip)).then(console.log)

    //     console.log(res)
    //   }),
    // )
    // console.log('---list---', list.length);
    for (let item of list) {
      try {
        await fxPrintSingle({ item, token })
      } catch { }
    }
  },
})

export const fxPrint = attach({
  effect: _fxPrint,
  source: {
    activeMap: $activeMap,
    products: $products,
    token: $token
  },
  mapParams: (params, state) => ({ ...state, ...params }),
})

sample({
  source: onPrint,
  target: fxPrint,
})
