import { gql } from '@apollo/client';
import { client } from '../../../graphql_client';

const toDays = 1
const fromDays = 30

/* DEFAULT DATE FOR ORDER QUERY */
const tomorrow = new Date(new Date().getTime() + toDays * 24 * 60 * 60 * 1000)
  .toISOString()
  .split(`T`)[0]

const lastWeek = new Date(new Date().setDate(new Date().getDate() - fromDays))
  .toISOString()
  .split(`T`)[0]

const query = gql`query MyQuery($from: AWSDate!, $to: AWSDate!){
  listOrdersByDate(from: $from, to: $to) {
    id
    name
    ship_date
    customer
    meals {
      name
      qty
      sku
    }
    staples {
      name
      qty
      sku
    }
    vitamins {
      name
      qty
      sku
    }
    to {
      city
      country
      name
      phone
      state
      street1
      zip
    }
  }
}`

export async function listOrders({ dateEnd, dateStart }) {
  const { data, errors } = await client.query({
    query,
    variables: {
      to: dateEnd,
      from: dateStart,
    },
  });

  if (errors?.length) {
    throw errors[0]
  }

  return data?.listOrdersByDate;
}

