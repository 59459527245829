import * as React from 'react';
import { createDomain } from 'effector-root';
import { Segment, Button, Dimmer, Loader, TransitionablePortal, Header } from 'semantic-ui-react';
import { useQuery, useMutation, gql } from '@apollo/client';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('PrintMode (Page)');
export const onPageStarted = domain.event();


const PRINT_MODE_SETTING = 'printMode';
const PRINT_MODE_SCHEMA = 'printSchema';

const listSettingsQuery = gql`query QuerySettings{
  listSettings {
    id
    value
  }
}`;

const getSettingQuery = gql`query QuerySetting($id: ID!){
  getSetting(id: $id) {
    id
    value
  }
}`;

const updateSettingMutation = gql`mutation UpdateSetting($id: ID!, $data: SettingInput){
  updateSetting(id: $id, data: $data) {
    id
    value
  }
}`;


export function PagePrintMode(props) {
  const { loading, error, data } = useQuery(listSettingsQuery);
  const [updateSetting, { loading: settingLoading, error: settingLoadingError }] = useMutation(updateSettingMutation);

  const printMode = React.useMemo(() => {
    return data?.listSettings?.find(s => s.id === PRINT_MODE_SETTING)?.value ?? 'paper'
  }, [data]);

  const printSchema = React.useMemo(() => {
    return data?.listSettings?.find(s => s.id === PRINT_MODE_SCHEMA)?.value ?? 'default'
  }, [data]);

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <div style={{ padding: '0 15px', overflowX: 'auto' }}>

        <Segment style={{ textAlign: 'center' }}>
          <Button
            loading={loading || settingLoading}
            positive={printMode === 'paper'}
            negative={printMode === 'virtual'}
            size='massive'
            onClick={() => updateSetting({ variables: { id: PRINT_MODE_SETTING, data: { value: printMode === 'virtual' ? 'paper' : 'virtual' } } })}
          >
            Print mode: {printMode}
          </Button>
        </Segment>
        <Segment style={{ textAlign: 'center' }}>
          <Button
            loading={loading || settingLoading}
            positive={printSchema === 'default'}
            negative={printSchema === 'backup'}
            size='massive'
            onClick={() => updateSetting({ variables: { id: PRINT_MODE_SCHEMA, data: { value: printSchema === 'default' ? 'backup' : 'default' } } })}
          >
            Print schema: {printSchema}
          </Button>
        </Segment>
      </div>
    </PrivateRoute>
  )
}
