import * as React from 'react';
import QRCode from 'qrcode.react';
import Barcode from '../../components/barcode';
import { createDomain } from 'effector-root';
import { Table, Checkbox, Dimmer, Loader, TransitionablePortal, Segment, Header, Pagination, Dropdown, Menu, Button, Modal, Form, Ref, Grid, GridColumn, Tab } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';

import MainMenu from '../../components/MainMenu';
import PrivateRoute from '../../components/PrivateRoute';

import { useLazyQuery, useMutation, gql, useApolloClient } from '@apollo/client';
const domain = createDomain('Products (Page)');
export const onPageStarted = domain.event();

const listProductsQuery = gql`
  query QueryListProductsExt($data: ListProductsInputExt) {
    listProductsExt(data: $data) {
      products
      {
        id
        name
        sku
        externalId
        nsId
        category {
          name
        }
        storeType
        upccode
        isBox
        internalName
        boxMealsLimit
        boxAcceptProductCategories
        hiddenInPnp
        boxAddProductsToBox
        boxAddProductsToBoxExtra
        isInsulation
        isMealMaterial
        minIcePacks
        isBoxMaterial
        isFakeProduct
        defaultWeight
        isIcePack
        isComponentMaterial
      }
      total
      range
    }
  }
`;

const updateProductMutation = gql`
  mutation updateProduct($id: ID!, $data: ProductInput!) {
    updateProduct(id: $id, data: $data) {
      id
      isBox
      internalName
      boxMealsLimit
      boxAcceptProductCategories
      hiddenInPnp
      boxAddProductsToBox
      boxAddProductsToBoxExtra
      isInsulation
      isMealMaterial
      minIcePacks
      isBoxMaterial
      isFakeProduct
      defaultWeight
      isIcePack
      isComponentMaterial
    }
  }
`;

const MUTATION_SEED_PRODUCTS = gql`
  mutation seedFakeProducts {
    seedFakeProducts
  }
`

const perPage = 15;

const storeTypeOptions = ['dry', 'fridge', 'empty'].map(s => ({ id: s, text: s, value: s }));
const categoryOptions = ['components', 'meals', 'vitamins', 'groceries', 'staples', 'packaging'].map(s => ({ id: s, text: s, value: s }));
const yesNoOptions = [{ id: 'yes', value: true, text: 'yes' }, { id: 'no', value: false, text: 'no' }];
const activeInactiveOptions = [{ id: false, name: 'Active' }, { id: true, name: 'Inactive' }].map(s => ({ id: s.text, text: s.name, value: s.id }));
const typeMaterialOptions = [
  { id: 1, name: 'Not specified' },
  { id: 2, name: 'Box' },
  { id: 3, name: 'Insulation' },
  { id: 4, name: 'Meal Material' },
  { id: 5, name: 'Box Material' },
  { id: 6, name: 'Gel Pack' },
  { id: 7, name: 'Component Material' }
].map(s => ({ id: s.text, text: s.name, value: s.id }));

export function PageProducts(props) {
  const [getProducts, { loading, error, data, refetch }] = useLazyQuery(listProductsQuery);
  const [getPackagingProducts, { loading: loadingPackaging, error: errorPackaging, data: dataPackaging }] = useLazyQuery(listProductsQuery);
  const [updateProduct, { loading: loadingUpdateProduct, error: errorUpdateProduct }] = useMutation(updateProductMutation);
  const [activePage, setActivePage] = React.useState(1);
  const [storeType, setStoreType] = React.useState([]);
  const [category, setCategory] = React.useState('packaging');
  const [filterIsBox, setFilterIsBox] = React.useState();
  const [filterHiddenInPnp, setFilterHiddenInPnp] = React.useState();
  const [showModal, setShowModal] = React.useState(false);
  const [selectedProduct, setSelectedProduct] = React.useState();
  const [boxAddProductsToBoxQty, boxAddProductsToBoxSetQty] = React.useState({});
  const [showSeedButton, setShowSeedButton] = React.useState(false);
  const client = useApolloClient();

  const { handleSubmit, formState, watch, reset, control, getValues } = useForm({
    reValidateMode: 'onChange'
  });

  const { isSubmitting } = formState;

  const productIsBox = watch('isBox');


  const onSubmit = (formData) => {
    const { typeMaterial, boxAddProductsToBox, ...obj } = formData;
    const aggregatedBoxAddProductsToBox = boxAddProductsToBox.map(p => Array(boxAddProductsToBoxQty[p] || 1).fill(p)).flat();
    updateProduct({ variables: { data: { ...obj, boxAddProductsToBox: aggregatedBoxAddProductsToBox }, id: selectedProduct.id } }).then(() => {
      setShowModal(false);
    })
  }

  const handleChangePage = (e, data) => {
    setActivePage(data.activePage);
  }

  React.useEffect(() => {
    if (category === 'packaging' && !loading && data) {
      const found = data?.listProductsExt?.products?.find(product => product.isFakeProduct);
      setShowSeedButton(!!!found);
    }
  }, [data, category, setShowSeedButton, loading])

  React.useEffect(() => {
    const data = {
      range: [(activePage - 1) * perPage, perPage * activePage],
      filter: {}
    }
    if (storeType.length) {
      data.filter.storeType = storeType;
    }
    if (category) {
      data.filter.categoryName = category;
    }
    if (filterIsBox !== undefined) {
      data.filter.isBox = filterIsBox;
    }
    if (filterHiddenInPnp !== undefined) {
      data.filter.hiddenInPnp = filterHiddenInPnp;
    }

    getProducts({ variables: { data } });
  }, [getProducts, activePage, storeType, category, filterIsBox, filterHiddenInPnp]);

  React.useEffect(() => {
    setActivePage(1);
  }, [storeType]);

  React.useEffect(() => {
    setActivePage(1);
  }, [category]);

  const handleEditProduct = (product) => {
    setSelectedProduct(product);
    const aggregateBoxAddProductsToBox = (product.boxAddProductsToBox || []).reduce((m, v) => ({ ...m, [v]: (m[v] || 0) + 1 }), {});
    boxAddProductsToBoxSetQty(aggregateBoxAddProductsToBox);
    reset({
      isBox: product.isBox ?? false,
      internalName: product.internalName ?? product.name,
      boxMealsLimit: product.boxMealsLimit ?? '',
      boxAcceptProductCategories: product.boxAcceptProductCategories ?? [],
      hiddenInPnp: product.hiddenInPnp ?? false,
      // boxAddProductsToBox: product.boxAddProductsToBox ?? [],
      boxAddProductsToBox: Object.keys(aggregateBoxAddProductsToBox),
      boxAddProductsToBoxExtra: product.boxAddProductsToBoxExtra ?? [],
      isInsulation: product.isInsulation ?? false,
      isMealMaterial: product.isMealMaterial ?? false,
      minIcePacks: product.minIcePacks ?? '',
      isBoxMaterial: product.isBoxMaterial ?? false,
      defaultWeight: product.defaultWeight ?? ''
    })
    getPackagingProducts({
      variables: {
        data: {
          range: [0, 100],
          filter: {
            // category: ['packaging']
            categoryName: 'packaging'
          }
        }
      }
    })
    setShowModal(true);
  }

  const handleSeedProducts = async () => {
    await client.mutate({ mutation: MUTATION_SEED_PRODUCTS });
    refetch();
  }

  const packagingProductsOptions = React.useMemo(() => {
    const list = dataPackaging?.listProductsExt?.products;
    if (list && selectedProduct) {
      return list.filter(p => (p.id !== selectedProduct.id && !!!p.isBox) || p.isBoxMaterial).map(p => ({ key: p.id, value: p.id, text: `${p.internalName ?? p.name} (${p.sku})` }));
    }
    return [];

  }, [selectedProduct, dataPackaging])

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Dropdown
            placeholder="category"
            options={categoryOptions}
            selection
            value={category}
            onChange={(e, { value }) => setCategory(value)}
          />
          <Dropdown
            placeholder="Active/Inactive"
            clearable
            options={activeInactiveOptions}
            selection
            value={filterHiddenInPnp}
            onChange={(e, { value }) => setFilterHiddenInPnp(value === '' ? undefined : value)}
          />
          {
            showSeedButton && <Button content="Seed Fake Products" onClick={handleSeedProducts} />
          }
        </Menu.Item>
      </Menu>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>ID</Table.HeaderCell>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>SKU</Table.HeaderCell>
            <Table.HeaderCell>External Id</Table.HeaderCell>
            <Table.HeaderCell>Netsuite Id</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Store Type</Table.HeaderCell>
            <Table.HeaderCell>UPC CODE</Table.HeaderCell>
            <Table.HeaderCell>Type of Material</Table.HeaderCell>
            <Table.HeaderCell>Active/Inactive</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listProductsExt?.products?.map((product, ind) => (
              <Table.Row key={product?.id ?? ind}>
                <Table.Cell>{product?.id ?? 'unknown'}</Table.Cell>
                <Table.Cell>{product?.internalName ?? product?.name}</Table.Cell>
                <Table.Cell>{product?.sku}</Table.Cell>
                <Table.Cell>{product?.externalId}</Table.Cell>
                <Table.Cell>{product?.nsId}</Table.Cell>
                <Table.Cell>{product?.category.name}</Table.Cell>
                <Table.Cell>{product?.storeType}</Table.Cell>
                <Table.Cell>{product?.upccode}</Table.Cell>
                <TypeMaterial {...product} />
                <Table.Cell>{product?.hiddenInPnp ? 'Inactive' : 'Active'}</Table.Cell>
                <Table.Cell><Button content="Edit" onClick={() => handleEditProduct(product)} size="tiny" /></Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
      <Pagination
        activePage={activePage}
        onPageChange={handleChangePage}
        totalPages={Math.ceil(data?.listProductsExt?.total / perPage)}
        firstItem={null}
        lastItem={null} />
      <Modal open={showModal} dimmer="blurring" as={Form} onSubmit={handleSubmit(onSubmit)} loading={isSubmitting}>
        <Modal.Header>
          Product Details
        </Modal.Header>
        <Modal.Content>
          <Grid>
            <Grid.Row>
              <Grid.Column width={6}>
                <div><b>Name:</b> {selectedProduct?.name}</div>
                <div><b>SKU:</b> {selectedProduct?.sku}</div>
                <div><b>Netsuite ID:</b> {selectedProduct?.nsId}</div>
                <div><b>Category:</b> {selectedProduct?.category.name}</div>
                <div><b>Store Type:</b> {selectedProduct?.storeType}</div>
                <div><b>UPC CODE:</b> {selectedProduct?.upccode}</div>
              </Grid.Column>
              <Grid.Column>
                <RenderQRCode {...selectedProduct} />
              </Grid.Column>
            </Grid.Row>
          </Grid>
          <hr />
          <Controller
            name="internalName"
            control={control}
            render={({ field: { ref, ...other } }) => (
              <Ref innerRef={ref}>
                <Form.Input {...other} label="Internal Name" placeholder="internal name" />
              </Ref>
            )}
          />
          <Controller
            name="hiddenInPnp"
            control={control}
            render={({ field: { ref, onChange, ...other } }) => (
              <Ref innerRef={ref}>
                <Form.Select {...other} onChange={(e, { value }) => onChange(value)} label="Active/Inactive" placeholder="Active/Inactive" options={activeInactiveOptions} />
              </Ref>
            )}
          />
          {
            productIsBox && (
              <>
                <Controller
                  name="boxMealsLimit"
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <Ref innerRef={ref}>
                      <Form.Input {...other} label="Box Meals Limit" placeholder="box meals limit" />
                    </Ref>
                  )}
                />
                <Controller
                  name="minIcePacks"
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <Ref innerRef={ref}>
                      <Form.Input {...other} label="Minimum Ice Packs" placeholder="minimum ice packs" />
                    </Ref>
                  )}
                />
                <Controller
                  name="defaultWeight"
                  control={control}
                  render={({ field: { ref, ...other } }) => (
                    <Ref innerRef={ref}>
                      <Form.Input {...other} label="Default Weight of packet Box" placeholder="Default Weight of packet Box" />
                    </Ref>
                  )}
                />
                <Controller
                  name="boxAcceptProductCategories"
                  control={control}
                  render={({ field: { ref, onChange, ...other } }) => (
                    <Ref innerRef={ref}>
                      <Form.Select {...other} onChange={(e, { value }) => onChange(value)} label="Box product type limitation" placeholder="product type limitation" options={categoryOptions} multiple clearable />
                    </Ref>
                  )}
                />
                <Controller
                  name="boxAddProductsToBox"
                  control={control}
                  render={({ field: { ref, onChange, ...other } }) => (
                    <Ref innerRef={ref}>
                      <Form.Select
                        {...other}
                        onChange={(e, { value }) => { onChange(value) }}
                        label="List of packaging items added to box (auto add)"
                        placeholder="list of packaging items added to box (by default)"
                        options={packagingProductsOptions}
                        multiple
                        clearable
                        renderLabel={({ text, value }) => ({
                          content:
                            <div style={{ display: 'inline-block' }}>
                              <div>{text}</div>
                              Quantity:
                              <span onClick={() => boxAddProductsToBoxSetQty(state => ({ ...state, [value]: (state[value] || 1) - 1 }))}>&nbsp;-&nbsp;</span>
                              <span> {boxAddProductsToBoxQty[value] || 1} </span>
                              <span onClick={() => boxAddProductsToBoxSetQty(state => ({ ...state, [value]: (state[value] || 1) + 1 }))}>&nbsp;+&nbsp;</span>
                            </div>
                        })}
                      />
                    </Ref>
                  )}
                />
                <Controller
                  name="boxAddProductsToBoxExtra"
                  control={control}
                  render={({ field: { ref, onChange, ...other } }) => (
                    <Ref innerRef={ref}>
                      <Form.Select {...other} onChange={(e, { value }) => onChange(value)} label="List of packaging items added to box" placeholder="list of packaging items added to box" options={packagingProductsOptions} multiple clearable />
                    </Ref>
                  )}
                />
              </>
            )
          }
        </Modal.Content>
        <Modal.Actions>
          <Button content="Update" type="submit" />
          <Button content="Cancel" type="button" onClick={() => setShowModal(false)} />
        </Modal.Actions>
      </Modal>

    </PrivateRoute>
  )
}

function CheckboxCell(props) {
  const { id, param, ...other } = props;
  return (
    <Table.Cell>
      <Checkbox checked={other[param]} />
    </Table.Cell>
  )
}

function RenderQRCode(props) {
  const { sku, category, isBox, nsId, onClick, upccode } = props;

  const QRData = React.useMemo(() => {
    let code = '';
    if (['vitamins', 'groceries'].includes(category?.name) && upccode) {
      code = upccode;
    } else if (['packaging'].includes(category?.name) && nsId) {
      code = `p${nsId}`;
    } else if (['meals'].includes(category?.name) && sku) {
      code = `https://www.lifechef.com/product/${sku}`;
    } else if (['components'].includes(category?.name) && sku) {
      // code = `https://www.lifechef.com/component/${sku}`;
      code = `c|${sku}`;
    }
    return code;
  }, [nsId, sku, category]);


  const handleQRCodeClick = () => {
    const canvas = document.getElementById("qr_code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = `${encodeURIComponent(QRData)}.png`;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  if (['vitamins'].includes(category?.name)) {
    return (
      <div>
        {
          QRData && <Barcode value={QRData} renderer="canvas" id="qr_code" onClick={handleQRCodeClick} />
        }
      </div>
    )
  }

  return (
    <div>
      {
        QRData && <QRCode value={QRData} level="H" size={200} onClick={handleQRCodeClick} id="qr_code" />
      }
    </div>
  )
}


function TypeMaterial(props) {
  const [updateProduct, { loading, error }] = useMutation(updateProductMutation);
  const value = React.useMemo(() => {
    if (props.isComponentMaterial) {
      return 7;
    }
    if (props.isIcePack) {
      return 6;
    }
    if (props.isBoxMaterial) {
      return 5;
    }
    if (props.isBox) {
      return 2;
    }
    if (props.isInsulation) {
      return 3;
    }
    if (props.isMealMaterial) {
      return 4;
    }
    return 1;
  }, [props]);

  const handleChange = React.useCallback((e, { value }) => {
    const data = {
      isBox: false,
      isInsulation: false,
      isMealMaterial: false,
      isBoxMaterial: false,
      isIcePack: false,
      isComponentMaterial: false
    };
    if (value === 2) {
      data.isBox = true;
    }
    if (value === 3) {
      data.isInsulation = true;
    }
    if (value === 4) {
      data.isMealMaterial = true;
    }
    if (value === 5) {
      data.isBoxMaterial = true;
      data.isBox = true;
    }
    if (value === 6) {
      data.isIcePack = true;
    }
    if (value === 7) {
      data.isComponentMaterial = true;
    }
    updateProduct({ variables: { id: props.id, data } });
  }, [updateProduct, props.id]);

  if (!props.id || props?.category?.name !== 'packaging') {
    return <Table.Cell></Table.Cell>
  }

  return (
    <Table.Cell>
      <Dropdown options={typeMaterialOptions} value={value} onChange={handleChange} loading={loading} />
    </Table.Cell>
  )
}
