import { API, graphqlOperation } from 'aws-amplify'

const subscription = `subscription {
    onCreateProduct {
      id
      name
      inventory
      description
      category
      price
      sku
    }
  }`

export function createSubscription({ handler }) {
  // API.graphql(graphqlOperation(subscription)).subscribe({
  //   next: ({value}) => handler(value.data.onCreateProduct),
  // })
}
