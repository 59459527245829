import Amplify from '@aws-amplify/core'

const CONFIG = require(`/core${process.env.NODE_ENV === 'development' ? '.local' : ''}.json`)

const REGION =
  process.env.RAZZLE_COGNITO_REGION || CONFIG.LCCore.ProjectRegion
const USER_POOL_ID =
  process.env.RAZZLE_COGNITO_USER_POOL_ID || CONFIG.LCCore.UserPoolId
const USER_WEB_CLIENT_ID =
  process.env.RAZZLE_COGNITO_USER_WEB_CLIENT_ID ||
  CONFIG.LCCore.UserPoolClientId
const IDENTITY_POOL_ID =
  process.env.RAZZLE_IDENTITY_POOL_ID ||
  CONFIG.LCCore.IdentityPoolId
const DOMAIN = process.env.RAZZLE_DOMAIN
const API_URL = process.env.API_URL || CONFIG.LCCore.GraphQLAPIURL
// const API_KEY = process.env.API_KEY || CONFIG.LCCore.AppSyncAPIKey

const config = {
  Auth: {
    region: REGION,
    userPoolId: USER_POOL_ID,
    userPoolWebClientId: USER_WEB_CLIENT_ID,
    identityPoolId: IDENTITY_POOL_ID,
    cookieStorage: {
      domain: DOMAIN || `localhost`,
      secure: DOMAIN ? true : false,
    },
  },
  ssr: true,
  aws_appsync_graphqlEndpoint: API_URL,
  aws_appsync_region: REGION,
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  // aws_appsync_apiKey: API_KEY,
}

Amplify.configure(config)

Amplify.Logger.LOG_LEVEL = `INFO`
