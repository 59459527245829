import * as React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { createDomain } from 'effector-root';
import { useLazyQuery, useQuery, useMutation, gql } from '@apollo/client';
import { TransitionablePortal, Segment, Header, Form, Ref, Table, Button, Modal, Dimmer, Loader } from 'semantic-ui-react';


import PrivateRoute from 'ui/components/PrivateRoute';
import MainMenu from '../../components/MainMenu';


const domain = createDomain('PrintMap (Page)');
export const onPageStarted = domain.event();


const apiKeysQuery = gql`query Printer_ApiKeysQuery {
  listPrintApiKeyNames
}`;

const printersQuery = gql`query Printer_PrintersQuery($key: String!){
  listPrintersByApiKeyName(key: $key) {
    id
    hostname
    computer_name
    name
  }
}`;

const printerMapsQuery = gql`query Printer_PrinterMapQuery {
  listPrinters {
    id
    apiKey
    name
    printerId
    schema
  }
}`;

const createPrintMap = gql`mutation Printer_MutationCreatePrinter($name: String!, $apiKey: String!, $printerId: String!, $schema: String!) {
    createPrinter(data: {name: $name, apiKey: $apiKey, printerId: $printerId, schema: $schema}) {
      id
      apiKey
      name
      printerId
      schema
    }
  }
`;

const updatePrintMap = gql`mutation Printer_MutationUpdatePrinter($id: ID!, $data: PrinterInput!) {
    updatePrinter(id: $id, data: $data) {
      id
      apiKey
      name
      printerId
      schema
    }
  }
`;

const deletePrintMap = gql`mutation Printer_MutationRemovePrinter($id: ID!) {
  removePrinter(id: $id)
}`;

const schemas = ['default', 'backup'];
const schemasOptions = schemas.map(s => ({ key: s, value: s, text: s }));

const defaultPrinterValues = { name: '', apiKey: '', printerId: '0', schema: schemas[0] };


export function PagePrintMap(props) {
  const { loading: apiKeyLoading, error: apiKeyError, data: apiKeyListData } = useQuery(apiKeysQuery);
  const { loading: printersMapLoading, error: printersMapError, data: printersMapData, refetch } = useQuery(printerMapsQuery);
  const [getPrinters, { loading: printersLoading, error: printersError, data: printersListData }] = useLazyQuery(printersQuery);
  const [createPrinter, { loading: createPrinterLoading, error: createPrinterError }] = useMutation(createPrintMap);
  const [updatePrinter, { loading: updatePrinterLoading, error: updatePrinterError }] = useMutation(updatePrintMap);
  const { handleSubmit, formState, watch, setValue, reset, control, getValues, trigger } = useForm({
    defaultValues: defaultPrinterValues,
    reValidateMode: 'onChange'
  });
  const { isSubmitting, errors } = formState;
  const [updatedPrinter, setUpdatedPrinter] = React.useState();
  const [showModal, setShowModal] = React.useState(false);

  const apiKey = watch('apiKey');

  const error = [apiKeyError, printersError, createPrinterError, printersMapError, updatePrinterError].filter(Boolean).join(', ');

  React.useEffect(() => {
    if (apiKeyListData) {
      const list = apiKeyListData.listPrintApiKeyNames;
      if (list?.length && !updatedPrinter) {
        setValue('apiKey', list[0]);
      }
    }
  }, [apiKeyListData, setValue])

  const onSubmit = (formData) => {
    const { printerId, schema, name, apiKey } = formData;
    if (updatedPrinter) {
      updatePrinter({ variables: { data: formData, id: updatedPrinter } }).then(() => {
        setUpdatedPrinter(undefined);
        reset(defaultPrinterValues);
        refetch();
        setShowModal(false);
      });

    } else {
      createPrinter({ variables: formData }).then(() => {
        reset(defaultPrinterValues);
        refetch();
        setShowModal(false);
      });
    }
  }

  React.useEffect(() => {
    if (apiKey) {
      getPrinters({ variables: { key: apiKey } })
    }
  }, [apiKey]);

  React.useEffect(() => {
    if (printersListData) {
      const list = printersListData.listPrintersByApiKeyName;
      if (list?.length && !updatedPrinter) {
        setValue('printerId', list[0]?.id);
      }
    }
  }, [printersListData, setValue]);

  const apiKeyOptions = React.useMemo(() => {
    const list = apiKeyListData?.listPrintApiKeyNames;
    if (list) {
      return list.map(k => ({ key: k, text: k, value: k }));
    }
    return [];
  }, [apiKeyListData]);

  const printerIdOptions = React.useMemo(() => {
    const list = printersListData?.listPrintersByApiKeyName;
    if (list) {
      return list.map((p) => {
        const name = [p.computer_name, p.name].filter(Boolean).join(' ');
        return { key: p.id, value: p.id, text: `${p.id} (${name})` };
      })
    }
    return [];
  }, [printersListData]);

  const handleEditPrinter = (data) => {
    setUpdatedPrinter(data.id);
    reset({
      name: data.name,
      printerId: data.printerId,
      schema: data.schema,
      apiKey: data.apiKey
    })
    setShowModal(true);
  }

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={printersMapLoading && !printersMapData} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Segment>
        <Button content="Add Printer" onClick={() => setShowModal(true)} />
        <Modal open={showModal} dimmer="blurring" as={Form} onSubmit={handleSubmit(onSubmit)} loading={isSubmitting}>
          <Modal.Header>{updatedPrinter ? "Update Printer" : "Add Printer"}</Modal.Header>
          <Modal.Content>
            <Form.Group>
              <Controller
                name="name"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...other } }) => (
                  <Ref innerRef={ref}>
                    <Form.Input {...other} onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="Printer Name" placeholder="printer name" />
                  </Ref>
                )}
              />
              <Controller
                name="apiKey"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...other } }) => (
                  <Ref innerRef={ref}>
                    <Form.Select {...other} onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="API Key Name" placeholder="API key name" loading={apiKeyLoading} options={apiKeyOptions} />
                  </Ref>
                )}
              />

              <Controller
                name="printerId"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, onChange, ...other } }) => (
                  <Ref innerRef={ref}>
                    <Form.Select {...other} onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="Printer ID" placeholder="printer id" loading={printersLoading} options={printerIdOptions} />
                  </Ref>
                )}
              />

              <Controller
                name="schema"
                control={control}
                rules={{ required: true }}
                render={({ field: { ref, onChange, onBlur, ...other } }) => (
                  <Ref innerRef={ref}>
                    <Form.Select {...other} onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="Schema" placeholder="schema" options={schemasOptions} />
                  </Ref>
                )}
              />
            </Form.Group>
          </Modal.Content>
          <Modal.Actions>
            <Button content={updatedPrinter ? "Update Printer" : "Add Printer"} disabled={isSubmitting || !formState.isValid} />
            <Button content="Cancel" type="button" onClick={() => { setUpdatedPrinter(undefined); reset(defaultPrinterValues); setShowModal(false); }} />
          </Modal.Actions>
        </Modal>
      </Segment>
      <PrinterMap data={printersMapData} refetch={refetch} onEdit={handleEditPrinter} />
    </PrivateRoute>
  )
}

function PrinterMap(props) {
  const { data, refetch, onEdit } = props;

  const printersData = React.useMemo(() => {
    const list = data?.listPrinters;
    if (list) {
      return list.map(l => ({ ...l, schema: l.schema ?? schemas[0] })).sort((a, b) => `${a.name}`.localeCompare(`${b.name}`));
    }
    return [];
  }, [data]);

  return (
    schemas.map(schema => (
      <Segment key={schema}>
        <h4>{schema} schema</h4>
        <Table celled>
          <Table.Header>
            <Table.Row>
              <Table.HeaderCell>Name</Table.HeaderCell>
              <Table.HeaderCell>API Key Name</Table.HeaderCell>
              <Table.HeaderCell>Printer ID</Table.HeaderCell>
              <Table.HeaderCell>Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              printersData.filter(l => l.schema === schema).map(l => <PrinterRow key={l.id} data={l} refetch={refetch} onEdit={onEdit} />)
            }
          </Table.Body>
        </Table>
      </Segment>
    ))
  )
}

function PrinterRow(props) {
  const { data, refetch, onEdit } = props;
  const { id, name, apiKey, printerId } = data;
  const [removePrinter, { loading: removePrinterLoading, error: removePrinterError }] = useMutation(deletePrintMap);

  const handleDeletePrinter = () => {
    removePrinter({ variables: { id } }).then(() => {
      refetch();
    })
  }

  return (
    <Table.Row>
      <Table.Cell>{name}</Table.Cell>
      <Table.Cell>{apiKey}</Table.Cell>
      <Table.Cell>{printerId}</Table.Cell>
      <Table.Cell><Button content="Delete" onClick={handleDeletePrinter} size="tiny" /><Button content="Edit" onClick={() => onEdit(data)} size="tiny" /></Table.Cell>
    </Table.Row>
  )
}

// function PrinterMap(props) {
//   const { data, refetch } = props;
//   const [removePrinter, { loading: removePrinterLoading, error: removePrinterError }] = useMutation(deletePrintMap);

//   const handleDeletePrinter = (printer) => {
//     const { id } = printer;
//     removePrinter({ variables: { id } }).then(() => {
//       refetch()
//     })
//   }

//   return (
//     <Segment>
//       <div>Printers Map</div>
//       {
//         schemas.map(schema => (
//           <div key={schema}>
//             <h4>{schema} schema</h4>
//             <div style={{ display: 'flex', width: 550 }}>
//               <div style={{ width: 100 }}>name</div>
//               <div style={{ width: 150 }}>apiKeyName</div>
//               <div style={{ width: 150 }}>printerId</div>
//               <div style={{ width: 150 }}>{' '}</div>
//             </div>
//             <hr />
//             {
//               data?.listPrinters?.filter(l => l.schema === schema).map(l => (
//                 <div key={`${l.id}`} style={{ display: 'flex', width: 550 }}>
//                   <div style={{ width: 100 }}>{l.name}</div>
//                   <div style={{ width: 150 }}>{l.apiKey}</div>
//                   <div style={{ width: 150 }}>{l.printerId}</div>
//                   <div style={{ width: 150 }}><button onClick={() => handleDeletePrinter(l)}>delete</button></div>
//                 </div>
//               ))
//             }
//           </div>
//         ))
//       }
//     </Segment>
//   )
// }

