import { contract } from 'lib/contract'
import { sample, guard } from 'effector-root'
import { $token } from 'feature/auth/model'

import * as fromProducts from 'feature/orders'

import * as page from './page'
import * as model from './model'
export { PageListSlip } from './page'

contract({
  page,
  model,
})

sample({
  source: fromProducts.$products,
  target: model.$products,
})

sample({
  clock: model.onPageStarted,
  source: {
    dateStart: model.$dateStart,
    dateEnd: model.$dateEnd,
    token: $token,
  },
  target: fromProducts.onQuery,
})


sample({
  source: model.onSet,
  target: fromProducts.onSet,
})

sample({
  source: model.onReset,
  target: fromProducts.onReset,
})

sample({
  source: fromProducts.$activeMap,
  target: model.$activeMap,
})

sample({
  source: model.onPrint,
  target: fromProducts.onPrint,
})

sample({
  clock: model.onQuery,
  // clock: [model.$dateStart, model.$dateEnd],
  source: {
    dateStart: model.$dateStart,
    dateEnd: model.$dateEnd,
    token: $token,
  },
  target: fromProducts.onQuery,
})

sample({
  source: fromProducts.fxQuery.pending,
  target: model.$pending,
})

sample({
  source: fromProducts._fxPrint.pending,
  target: model.$printPending,
})

// fromProducts.$products
