import React from 'react';
import JsBarcode from 'jsbarcode';

function Barcode(props) {
  const { value, id, onClick, ...otherProps } = props;
  const ref = React.useRef();

  React.useEffect(() => {
    if (value) {
      new JsBarcode(ref.current, value, otherProps);
    }
  }, [value, otherProps]);

  return (
    <canvas ref={ref} id={id} onClick={onClick} />
  )
}

export default Barcode;