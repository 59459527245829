import { guard } from 'effector-root'
import { $token } from 'feature/auth/model'

import * as model from './model'

export {
  onQuery,
  fxQuery,
  _fxPrint,
  onCreate,
  onCreateSubscription,
  $products,
  $activeMap,
  onSet,
  onPrint,
  onReset
} from './model'

guard({
  clock: model.onCreate,
  filter: $token,
  source: {
    token: $token,
  },
  target: model.fxCreate,
})

// guard({
//   clock: model.onQuery,
//   filter: $token,
//   source: {
//     token: $token,
//   },
//   target: model.fxQuery,
// })
