import * as React from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { createDomain } from 'effector-root'

import { useEvent } from 'effector-react/ssr'
import { reflect } from '@effector/reflect/ssr'
import { variant } from '@effector/reflect/ssr'

import { ROLES } from 'feature/auth/model'

import * as A from './styled'

const domain = createDomain(`Home (Page)`)

export const onPageStarted = domain.event()
export const onUsernameChange = domain.event()
export const onPasswordChange = domain.event()
export const onSignOut = domain.event()
export const onSubmit = domain.event()
export const onQuery = domain.event()
export const onCreate = domain.event()

export const $username = domain.store(``)
export const $password = domain.store(``)
export const $products = domain.store([])

export const $currentRole = domain.store(ROLES.GUEST)
export const $currentUsername = domain.store(null)

export function PageHome(props) {
  useMount(props)

  return (
    <A.FormGroup>
      <Form>
        <Username />
        <Password />
        <ForgotPassword />
        <Submit />
      </Form>
      <SignOut />
      <RedirectBtn />
      <PrinterMapBtn />
      <CustomersBtn />
      <OrdersBtn />
      <OrdersReportBtn />
      <PrintModeBtn />
      <ProductsBtn />
    </A.FormGroup>
  )
}

function useMount(props) {
  const onMount = useEvent(onPageStarted)

  React.useEffect(() => {
    onMount(props)
  }, [])
}

const Form = reflect({
  view: styled.form``,
  bind: {
    onSubmit: onSubmit.prepend(preventDefault),
  },
})

const ForgotPassword = variant({
  source: $currentRole,
  cases: {
    [ROLES.GUEST]: () => <Link to="/forgot">Forgot Password</Link>,
  },
  default: null
})

const Username = variant({
  source: $currentRole,
  cases: {
    [ROLES.GUEST]: Input,
  },
  default: null,
  bind: {
    autoComplete: `email`,
    label: `Username`,
    value: $username,
    onChange: onUsernameChange.prepend(getValue),
  },
})

const Password = variant({
  source: $currentRole,
  cases: {
    [ROLES.GUEST]: Input,
  },
  default: null,
  bind: {
    autoComplete: `current-password`,
    label: `Password`,
    type: `password`,
    value: $password,
    onChange: onPasswordChange.prepend(getValue),
  },
})

const Submit = variant({
  source: $currentRole,
  cases: {
    [ROLES.GUEST]: Button,
  },
  default: null,
  bind: {
    type: `submit`,
    label: `Submit`,
  },
})

const SignOut = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: Button,
  },
  default: null,
  bind: {
    label: $currentUsername.map((v) => `SignOut (${v})`),
    onClick: onSignOut.prepend(noop),
  },
})

const RedirectBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: RedirectButton,
  },
  default: null,
})

function RedirectButton() {
  return (
    <A.Redirect>
      <Link to="list-slip">Go to List Slip</Link>
    </A.Redirect>
  )
}

const PrinterMapBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: PrinterMapButton,
  },
  default: null,
})

function PrinterMapButton() {
  return (
    <>
      <br />
      <A.Redirect>
        <Link to="print-map">Go to Printer Map</Link>
      </A.Redirect>
    </>
  )
}

const CustomersBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: CustomersButton,
  },
  default: null,
})

function CustomersButton() {
  return (
    <>
      <br />
      <A.Redirect>
        <Link to="customers">Go to Customers</Link>
      </A.Redirect>
    </>
  )
}

const OrdersBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: OrdersButton,
  },
  default: null,
})

function OrdersButton() {
  return (
    <>
      <br />
      <A.Redirect>
        <Link to="orders">Go to Orders</Link>
      </A.Redirect>
    </>
  )
}

const OrdersReportBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: OrdersReportButton,
  },
  default: null,
})

function OrdersReportButton() {
  return (
    <>
      <br />
      <A.Redirect>
        <Link to="orders-report">Go to Orders Report</Link>
      </A.Redirect>
    </>
  )
}

const PrintModeBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: PrintModeButton,
  },
  default: null,
})

function PrintModeButton() {
  return (
    <>
      <br />
      <A.Redirect>
        <Link to="print-mode">Go to Print Mode</Link>
      </A.Redirect>
    </>
  )
}

const ProductsBtn = variant({
  source: $currentRole,
  cases: {
    [ROLES.USER]: ProductsButton,
  },
  default: null,
})

function ProductsButton() {
  return (
    <>
      <br />
      <A.Redirect>
        <Link to="products">Go to Products</Link>
      </A.Redirect>
    </>
  )
}

const Products = reflect({
  view: List,
  bind: {
    products: $products,
  },
})

function List({ products }) {
  return (
    <div>
      <A.List>
        {products.map(({ id, name }) => (
          <A.Label key={id}>
            <b>{id}</b>
            {name}
          </A.Label>
        ))}
      </A.List>
    </div>
  )
}

function Button({ label, type = `button`, onClick }) {
  return (
    <div>
      <A.Button type={type} onClick={onClick}>
        {label}
      </A.Button>
    </div>
  )
}

function Input({ autoComplete, label = ``, type, value, onChange }) {
  return (
    <div>
      <A.Label>
        {label}
        <A.Input
          autoComplete={autoComplete}
          type={type}
          value={value}
          onChange={onChange}
        />
      </A.Label>
    </div>
  )
}

function getValue(e) {
  return e.currentTarget.value
}

function preventDefault(e) {
  e.preventDefault()
}

function noop() { }

