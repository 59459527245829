import { sample } from 'effector-root'
import { request } from 'ssr/model'
import { onRestoreServerSession } from './model'

export {
  onSignIn,
  onSignOut,
  onRestoreSession,
  onRestoreServerSession,
  $currentUser,
  $currentRole,
  ROLES,
  $authState
} from './model'

sample({
  source: request,
  target: onRestoreServerSession,
})
