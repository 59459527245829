import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox, TableCell } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { gql, useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import format from 'date-fns/format';
import startOfWeek from 'date-fns/startOfWeek';
import endOfWeek from 'date-fns/endOfWeek';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';
import { camelCase } from 'lodash';

const domain = createDomain('Customer Order Breakdown (Page)');
export const onPageStarted = domain.event();



const listProductsQuery = gql`
  query QueryListProductsExt($data: ListProductsInputExt) {
    listProductsExt(data: $data) {
      products
      {
        id
        name
        sku
      }
      total
      range
    }
  }
`;

const listOrdersQueryExt = gql`
  query listOrders($data: ListOrdersInputExt) {
    listOrdersExt(data: $data) {
      orders {
        id
        shipBy
        status
        zone
        carrier
        deliveryInstruction
        zipCode
        customer {
          firstName
          lastName
          email
        }
        items {
          product {
            sku
            category {
              name
            }
          }
        }
      }
    }
  }
`;

const orderStatuses = ['processing', 'picked', 'packed', 'shipping', 'shipped', 'hold', 'failed', 'delivered'];
const availableStatuses = orderStatuses.map(s => ({ id: s, text: s, value: s }));

export function PageBreakdownCustomerOrder(props) {
  const [fromDate, setFromDate] = React.useState(startOfWeek(new Date(), { weekStartsOn: 1 }));
  const [toDate, setToDate] = React.useState(endOfWeek(new Date(), { weekStartsOn: 1 }));
  const [loading, setLoading] = React.useState(null);
  const [coreData, setCoreData] = React.useState();
  const client = useApolloClient();
  const [updatedAt, setUpdatedAt] = React.useState();
  const [statuses, setStatuses] = React.useState(['processing']);

  const { error: productError, data: productData } = useQuery(listProductsQuery, {
    errorPolicy: 'all',
    variables: {
      data: {
        filter: {
          categoryName: 'components',
          hiddenInPnp: false
        }
      }
    }
  });

  const products = React.useMemo(() => {
    if (productData) {
      return productData?.listProductsExt?.products
        .map(p => p)
        .sort((a, b) => `${a.name}`
          .localeCompare(`${b.name}`));
    }
    return [];
  }, [productData]);

  const handleMakeReport = async () => {
    const requestData = () => {
      return client.query({
        query: listOrdersQueryExt, variables: {
          data: {
            filter: {
              from: format(fromDate, "yyyy-MM-dd"),
              to: format(toDate, "yyyy-MM-dd"),
              statuses,
              withoutQa: true,
            },
            range: [0, 600]
          }
        }
      })
    }
    setLoading('Loading data ');
    const responseCore = await requestData();
    // console.log('---responseCore---', responseCore.data?.listOrdersExt?.orders);
    setCoreData(responseCore.data?.listOrdersExt?.orders);
    setLoading(null);
    setUpdatedAt(new Date());
  }


  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={!!loading} inverted>
        <Loader>{loading}</Loader>
      </Dimmer>
      <Menu compact>
        <Menu.Item>
          <Dropdown placeholder="Status" multiple options={availableStatuses} selection value={statuses} onChange={(e, { value }) => setStatuses(value)} />
          <SemanticDatepicker placeholder="From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
          <Button content="Load Data" onClick={handleMakeReport} />
        </Menu.Item>
      </Menu>
      {coreData &&
        <div style={{ margin: 15 }}>
          {
            updatedAt && <div>Data Loaded: {JSON.stringify(updatedAt)}</div>
          }
          <br />
        </div>
      }
      <div style={{ overflow: 'scroll', height: 'calc(100vh - 200px)' }} className='freeze-col'>
        <Table celled>
          <Table.Header>
            <Table.Row verticalAlign='top'>
              <Table.HeaderCell>#</Table.HeaderCell>
              <Table.HeaderCell>Customer</Table.HeaderCell>
              <Table.HeaderCell>Order Number</Table.HeaderCell>
              <Table.HeaderCell>Meals</Table.HeaderCell>
              <Table.HeaderCell>Component Total</Table.HeaderCell>
              {
                products.map(product => <Table.HeaderCell key={product.sku}>{product.name}</Table.HeaderCell>)
              }
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {
              coreData?.map((order, ind) => (
                <Table.Row key={order.id}>
                  <Table.Cell>{ind + 1}</Table.Cell>
                  <Table.Cell>{order.customer?.firstName} {order.customer?.lastName}</Table.Cell>
                  <Table.Cell>{order.id}</Table.Cell>
                  <Table.Cell>{order.items?.filter(item => item.product?.category?.name === 'meals')?.length}</Table.Cell>
                  <Table.Cell>{order.items?.filter(item => item.product?.category?.name === 'components')?.length}</Table.Cell>
                  {
                    products.map(product => <Table.Cell key={product.sku}><Cell data={order.items?.filter(item => item.product?.sku === product.sku)?.length ?? ''} /></Table.Cell>)
                  }
                </Table.Row>
              ))
            }
          </Table.Body>
          <Table.Footer>
            <Table.Row verticalAlign='top'>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell></Table.HeaderCell>
              <Table.HeaderCell>{coreData?.reduce((m, order) => m + order?.items?.filter(item => item?.product?.category?.name === 'meals')?.length, 0)}</Table.HeaderCell>
              <Table.HeaderCell>{coreData?.reduce((m, order) => m + order?.items?.filter(item => item?.product?.category?.name === 'components')?.length, 0)}</Table.HeaderCell>
              {
                products.map(product => <Table.HeaderCell key={product.sku}>{coreData?.reduce((m, order) => m + order?.items?.filter(item => item?.product?.sku === product.sku)?.length, 0)}</Table.HeaderCell>)
              }
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </PrivateRoute>
  )
}

function Cell(props) {
  const { data } = props;
  return data > 0 ? data : '';
}