// import { variant } from '@effector/reflect/ssr';
// import { Redirect } from 'react-router-dom';
// import { $authState } from 'feature/auth';

// const PrivateRoute = variant({
//   source: $authState,
//   cases: {
//     0: () => <div>...</div>,
//     1: ({ children }) => children,
//     2: () => <Redirect to="/" />
//   }
// });

// export default PrivateRoute;

import { Auth } from 'aws-amplify';
import React from 'react';
import { Redirect } from 'react-router-dom';

export default function PrivateRoute(props) {
  const { children, groups = [] } = props;
  const [authState, setAuthState] = React.useState(0);

  React.useEffect(() => {
    const getToken = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();

        const token = user.signInUserSession?.idToken?.jwtToken;
        if (!token) {
          setAuthState(2);
        } else {
          const userGroups = user.signInUserSession?.idToken?.payload['cognito:groups'] ?? [];
          if (groups.length === 0) {
            setAuthState(1);
          } else {
            const intersection = groups.filter(x => userGroups.includes(x));
            setAuthState(intersection.length ? 1 : 2);
          }
        }

      } catch (ex) {
        setAuthState(2);
      }

    }
    getToken();
  }, []);

  if (authState === 0) {
    return <div>...</div>
  }

  if (authState === 1) {
    return children;
  }

  if (authState === 2) {
    return <Redirect to="/" />
  }
}