
import { Auth } from 'aws-amplify';
import { ApolloClient, InMemoryCache, ApolloProvider, createHttpLink } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

const CONFIG = require(`/core${process.env.NODE_ENV === 'development' ? '.local' : ''}.json`);
const API_URL = CONFIG.LCCore.GraphQLAPIURL;

const httpLink = createHttpLink({
  uri: process.env.RAZZLE_CORE_LOCAL_API
});

// const delay = (ms) => new Promise(r => setTimeout(r, ms));

const authLink = setContext(async ({ operationName }, { headers }) => {
  try {
    const user = await Auth.currentAuthenticatedUser();
    const token = user.signInUserSession?.idToken?.jwtToken;
    if (process.env.NODE_ENV === 'development') {
      return {
        uri: operationName.startsWith('Printer_') ? process.env.RAZZLE_PRINTER_LOCAL_API : process.env.RAZZLE_CORE_LOCAL_API,
        headers: {
          ...headers,
          'x-user-name': user.username,
          'x-app-name': 'admin_core'
        }
      }
    }
    return {
      uri: API_URL,
      headers: {
        ...headers,
        authorization: token,
        'x-user-name': user.username,
        'x-app-name': 'admin_core'
      }
    }
  }
  catch (ex) {
    return {}
  }
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'cache-and-network',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all'
    }
  }
})

export function GraphqlWrapper({ children }) {
  return (
    <ApolloProvider client={client}>{children}</ApolloProvider>
  )
}