import { API } from './config'

const query = `mutation {
  createProduct(product: {name: "second", price: 2.5, category: "c2", description: "description2"}) {
    id
    name
    inventory
    description
    category
    price
    sku
  }
}`

export async function createProduct({ token }) {
  const endpointAPI = process.env.NODE_ENV === 'development' ? process.env.RAZZLE_CORE_LOCAL_API ?? API : API;
  const r = await fetch(endpointAPI, {
    body: JSON.stringify({
      query,
    }),
    headers: {
      authorization: token,
      'content-type': 'application/json',
    },
    method: 'POST',
  })

  const { data, errors } = await r.json()

  if (errors?.length) {
    throw errors[0]
  }

  return data.createProduct
}
