import * as React from 'react';
import { useQuery, gql } from '@apollo/client';


const printerMapsQuery = gql`query Printer_PrinterMapQuery {
  listPrinters {
    id
    apiKey
    name
    printerId
    schema
  }
}`

function SelectPrinter(props) {

  const [selectedPrinter, setSelectedPrinter] = React.useState();
  const { loading, error, data } = useQuery(printerMapsQuery);


  React.useEffect(() => {
    if (data) {
      const value = localStorage.getItem('listSlipPrinter');
      setSelectedPrinter(value ?? 'slip');
    }
  }, [data]);

  const handleSelect = (event) => {
    // console.log('---select printer---', event.target.value);
    const value = event.target?.value;
    setSelectedPrinter(value);
    if (value) {
      localStorage.setItem('listSlipPrinter', value);
    }
  }

  return (
    <select style={{ marginLeft: 15 }} onChange={handleSelect} value={selectedPrinter}>
      <option disabled>Select Printer</option>
      {
        data?.listPrinters?.map((k) => <option key={k.id}>({k.schema}) {k.name}</option>)
      }
    </select>
  )
}

export default SelectPrinter;

