import styled from 'styled-components'

export const FormGroup = styled.div`
  display: block;
  width: 300px;
  margin: 50px auto;
  padding: 0 10px;
`

export const Button = styled.button`
  cursor: pointer;
  margin-bottom: 0.5em;
  display: block;
  width: 100%;
  border: none;
  border-radius: 3px;
  padding: 5px 10px;
  background: #0a6629;
  text-align: center;
  color: #fff;
`

export const Label = styled.label`
  margin-bottom: 0.5em;
  display: block;
`

export const Input = styled.input`
  box-sizing: border-box;
  padding: 0.5em;
  background: papayawhip;
  border: none;
  border-radius: 3px;
  width: 100%;
  margin-bottom: 0.5em;
`

export const Redirect = styled.div`
  background: #0a6629;
  border-radius: 3px;
  a {
    display: block;
    width: 100%;
    padding: 5px 10px;
    text-align: center;
    text-decoration: none;
    color: #fff;
  } 
`

export const List = styled.ul`
  list-style: none;
  padding: 0;
`
