import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox, Tab, TableHeader } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { useLazyQuery, gql, useMutation, useQuery, useApolloClient } from '@apollo/client';
import useInfiniteScroll from 'react-infinite-scroll-hook';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('Orders (Page)');
export const onPageStarted = domain.event();

const listCarriers = gql`
  query listCarriers {
    listCarriers {
      name
      value
    }
  }
`;

const createDeliveryRoute = gql`
  mutation createDeliveryRoute($data: DeliveryRouteInput!) {
    createDeliveryRoute(data: $data)
  }
`;

const listOrderLogsQuery = gql`
  query listOrderLogs($id: ID!) {
    listOrderLogs(id: $id) {
      id
      userIp
      userName
      appName
      orderId
      oldStatus
      newStatus
      changedAt
      text
    }
  }
`;

const listOrdersQueryExt = gql`
  query listOrders($data: ListOrdersInputExt) {
    listOrdersExt(data: $data) {
      orders {
        id
        shipBy
        status
        zone
        carrier
        deliveryInstruction
        zipCode
        customer {
          firstName
          lastName
          email
        }
        billing {
          firstName
          lastName
          address1
          state
          postcode
          country
          city
          phone
        }
        shipping {
          firstName
          lastName
          address1
          state
          postcode
          country
          city
          phone
        }
        items {
          weight
          orderItemId
          labelAws
          product {
            name
            isBox
            isInsulation
            internalName
            category {
              name
            }
          }
        }
        deliveryRoute
        defaultCarrier
        version
      }
      total
      range
    }
  }
`;

const updateOrderMutation = gql`
  mutation updateOrder($id: ID!, $data: OrderInput!) {
    updateOrder(id: $id, data: $data) {
      id
      status
      deliveryRoute
      defaultCarrier
    }
  }
`;

const syncOrderMutation = gql`
  mutation syncOrder($id: ID!) {
    syncOrder(id: $id) {
      order_id
    }
  }
`;

const orderStatuses = ['processing', 'picked', 'packed', 'shipping', 'shipped', 'hold', 'failed'];
const statuses = orderStatuses.map(s => ({ id: s, text: s, value: s }));

const archivedOptions = [{ id: 'archived', value: true, text: 'Show Archived' }, { id: 'active', value: false, text: 'Show Active' }];

const perPage = 50;

const teams = ['US', 'QA', 'DEV'].map(s => ({ id: s, text: s, value: s }));

export function PageOrders(props) {
  const [status, setStatus] = React.useState('');
  const [carrier, setCarrier] = React.useState([]);
  const [fromDate, setFromDate] = React.useState(sub(new Date(), { days: 1 }));
  const [toDate, setToDate] = React.useState(add(new Date(), { days: 1 }));
  const [selectedOrder, setSelectedOrder] = React.useState();
  const [search, setSearch] = React.useState('');
  const [filter, setFilter] = React.useState();
  const [archived, setArchived] = React.useState(false);
  const { loading: carriersLoading, data: carriersData } = useQuery(listCarriers);
  const [showDeliverCheckboxes, setShowDeliverCheckboxes] = React.useState(false);
  const [orderForRoute, setOrderForRoute] = React.useState([]);
  const [updatingOrders, setUpdatingOrder] = React.useState(false);
  const client = useApolloClient();
  const [team, setTeam] = React.useState('US');
  const [showLogs, setShowLogs] = React.useState(false);

  const carriers = React.useMemo(() => {
    if (carriersData?.listCarriers) {
      return carriersData?.listCarriers?.map(carrier => ({ id: carrier.value, value: carrier.value, text: carrier.name }));
    }
    return [];
  }, [carriersData]);

  React.useEffect(() => {
    if (fromDate && toDate) {
      setFilter({
        from: format(fromDate, "yyyy-MM-dd"),
        to: format(toDate, "yyyy-MM-dd"),
        status,
        q: search,
        archived,
        defaultCarrier: carrier
      });
    } else {
      setFilter(null);
    }
  }, [status, fromDate, toDate, archived, carrier]);


  const [getOrdersExt, { loading, error, data, fetchMore, refetch }] = useLazyQuery(listOrdersQueryExt, {
    errorPolicy: 'all'
  });


  const hasNextPage = React.useMemo(() => {
    return data?.listOrdersExt?.total > data?.listOrdersExt?.range[1];
  }, [data]);

  const loadMore = () => {
    const range = [data?.listOrdersExt?.range[0] + perPage, data?.listOrdersExt?.range[1] + perPage];
    fetchMore({
      variables: {
        data: {
          filter,
          range
        }
      },
      updateQuery: (prev, { fetchMoreResult }) => (
        {
          listOrdersExt: {
            total: fetchMoreResult?.listOrdersExt?.total,
            range: fetchMoreResult?.listOrdersExt?.range,
            orders: [...prev.listOrdersExt.orders, ...fetchMoreResult.listOrdersExt.orders]
          }
        }
      )
    })
  }

  const [sentryRef] = useInfiniteScroll({
    loading,
    hasNextPage,
    onLoadMore: loadMore,
    disabled: !!error,
    rootMargin: '0px 0px 400px 0px',
  });


  React.useEffect(() => {
    if (filter) {
      getOrdersExt({
        variables: {
          data: {
            filter,
            range: [0, perPage]
          }
        }
      })
    }
  }, [filter, getOrdersExt]);

  const handleExportToCSV = () => {
    const csvContent = "data:text/csv;charset=utf-8," +
      "id, defaultCarrier, zipCode, status, shipBy, customer, zone\n" +
      data?.listOrdersExt?.orders?.map(row => `${row.id},${row.defaultCarrier},${row.zipCode},${row.status},${row.shipBy},${row.billing.firstName} ${row.billing.lastName},${row.zone}`).join('\n');
    const encodedUri = encodeURI(csvContent);
    window.open(encodedUri);
  }

  const handleSearch = () => {
    setFilter(state => ({ ...state, q: search }))
  }

  const handleCreateRoute = async () => {
    setUpdatingOrder(true);
    const date = format(new Date(), 'M-dd-yyyy');
    const stage = capitalizeFirstLetter(process.env.RAZZLE_LC_STAGE_VERSION);
    const response = await client.mutate({ mutation: createDeliveryRoute, variables: { data: { date, stage, team } } });
    const routeName = response?.data?.createDeliveryRoute;
    for (const orderId of orderForRoute) {
      await client.mutate({ mutation: updateOrderMutation, variables: { id: orderId, data: { deliveryRoute: routeName } } });
    }
    setOrderForRoute([]);
    setShowDeliverCheckboxes(false);
    setUpdatingOrder(false);
  }

  const panes = [
    {
      menuItem: 'Status Logs',
      render: () => <OrderLogs id={selectedOrder?.id} />
    },
    {
      menuItem: 'RAW data',
      render: () => <Tab.Pane attached={false}><pre>{JSON.stringify(selectedOrder, null, ' ')}</pre></Tab.Pane>
    }
  ]

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Dropdown options={archivedOptions} selection value={archived} onChange={(e, { value }) => setArchived(value)} />
          <Dropdown placeholder="Status" clearable options={statuses} selection value={status} onChange={(e, { value }) => setStatus(value)} />
          <Dropdown placeholder="Default Carrier" clearable options={carriers} multiple selection value={carrier} onChange={(e, { value }) => setCarrier(value)} />
          <SemanticDatepicker placeholder="Ship From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="Ship To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
          <Input placeholder="Search..." value={search} onChange={(e, { value }) => setSearch(value)} action={{ content: 'Search', onClick: handleSearch }} />
        </Menu.Item>
      </Menu>
      <br />
      <Menu compact>
        <Menu.Item>
          <Button onClick={handleExportToCSV}>Export to CSV</Button>
        </Menu.Item>

        <Menu.Item>
          {
            !showDeliverCheckboxes &&
            <Button onClick={() => setShowDeliverCheckboxes(true)}>Create Route</Button>
          }
          {
            Boolean(showDeliverCheckboxes && !orderForRoute.length) &&
            <Button onClick={() => setShowDeliverCheckboxes(false)}>Cancel</Button>
          }
          {
            Boolean(showDeliverCheckboxes && orderForRoute.length) && (
              <>
                <span>Team:</span>
                {' '}
                <Dropdown placeholder="Team" options={teams} value={team} onChange={(e, { value }) => setTeam(value)} la />
                {' '}
                <Button onClick={handleCreateRoute} loading={updatingOrders}>Save Route</Button>
              </>
            )
          }
        </Menu.Item>
      </Menu>
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell width="1">#</Table.HeaderCell>
            <Table.HeaderCell width="1">ID</Table.HeaderCell>
            {
              showDeliverCheckboxes &&
              <Table.HeaderCell width="1">Add to delivery route</Table.HeaderCell>
            }
            {
              showDeliverCheckboxes &&
              <Table.HeaderCell width="2">Delivery Route</Table.HeaderCell>
            }
            <Table.HeaderCell width="2">Default Carrier</Table.HeaderCell>
            <Table.HeaderCell width="1">Zip Code</Table.HeaderCell>
            <Table.HeaderCell width="1">Status</Table.HeaderCell>
            <Table.HeaderCell width="1">Ship By</Table.HeaderCell>
            <Table.HeaderCell width="1">Customer</Table.HeaderCell>
            <Table.HeaderCell width="1">Zone</Table.HeaderCell>
            {/* <Table.HeaderCell /> */}
          </Table.Row>
        </Table.Header>
        <Table.Body>

          {
            data?.listOrdersExt?.orders?.map((order, ind) => (
              <Table.Row key={order.id}>
                <Table.Cell>{ind + 1}/{data?.listOrdersExt?.total}</Table.Cell>
                <Table.Cell onClick={() => setSelectedOrder(order)}>{order.id}</Table.Cell>
                {
                  showDeliverCheckboxes &&
                  <Table.Cell width="1"><Checkbox checked={orderForRoute.includes(order.id)} onChange={(e, { checked }) => {
                    setOrderForRoute(state => {
                      if (checked) {
                        return [...state, order.id];
                      }
                      return state.filter(id => id !== order.id);
                    })
                  }} /></Table.Cell>
                }
                {
                  showDeliverCheckboxes &&
                  <Table.Cell>{order.deliveryRoute}</Table.Cell>
                }
                <Table.Cell><Carrier list={carriers} value={order?.defaultCarrier} orderId={order?.id} /></Table.Cell>
                <Table.Cell>{order.zipCode}</Table.Cell>
                <Table.Cell>{order.status}</Table.Cell>
                <Table.Cell>{order.shipBy}</Table.Cell>
                {/* <Table.Cell>{order?.defaultCarrier}</Table.Cell> */}
                <Table.Cell>{order.billing?.firstName} {order.billing?.lastName}</Table.Cell>
                <Table.Cell>{order.zone}</Table.Cell>
                {/* <OrderSyncCell {...order} /> */}
              </Table.Row>
            ))
          }
          {
            (loading || hasNextPage) && (
              <Table.Row>
                <Table.Cell>
                  <div ref={sentryRef}>Loading...</div>
                </Table.Cell>
              </Table.Row>
            )
          }

        </Table.Body>
      </Table>
      <Modal open={!!selectedOrder} dimmer="blurring">
        <Modal.Header>Order Details #{selectedOrder?.id}</Modal.Header>
        <Modal.Content scrolling>
          <Tab menu={{ secondary: 'true', pointing: 'true' }} panes={panes} />
        </Modal.Content>
        <Modal.Actions>
          <Button content="Close" onClick={() => setSelectedOrder(undefined)} />
        </Modal.Actions>
      </Modal>
    </PrivateRoute>
  )
}

function OrderSyncCell(props) {
  const { id } = props;
  const [syncOrder, { loading, error }] = useMutation(syncOrderMutation);

  return (
    <Table.Cell>
      <Button loading={loading} onClick={() => syncOrder({ variables: { id } })}>Sync #{id}</Button>
    </Table.Cell>
  )
}

function Carrier(props) {
  const { value, list, orderId } = props;
  const [updateOrder, { loading, error }] = useMutation(updateOrderMutation);

  const handleChange = React.useCallback((e, { value }) => {
    updateOrder({ variables: { id: orderId, data: { defaultCarrier: value } } });
  }, [updateOrder, orderId]);

  if (list) {
    const found = list.find(carrier => carrier.value === value) || {};
    // return <span>{found?.text}</span>
    return <Dropdown options={list} value={found?.value} onChange={handleChange} loading={loading} />
  }

  return <span>' '</span>
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}


function OrderLogs(props) {
  const { id } = props;

  const { data, loading } = useQuery(listOrderLogsQuery, {
    errorPolicy: 'all',
    variables: {
      id
    }
  });


  return (
    <Tab.Pane attached={false} loading={loading}>
      <Table>
        <TableHeader>
          <Table.Row>
            <Table.HeaderCell>Status</Table.HeaderCell>
            <Table.HeaderCell>User</Table.HeaderCell>
            <Table.HeaderCell>App</Table.HeaderCell>
            <Table.HeaderCell>Changed At</Table.HeaderCell>
            <Table.HeaderCell>Text</Table.HeaderCell>
          </Table.Row>
        </TableHeader>
        <Table.Body>
          {
            data?.listOrderLogs.map(record => (
              <Table.Row key={record.id}>
                <Table.Cell>{`${record.oldStatus} -> ${record.newStatus}`}</Table.Cell>
                <Table.Cell>{record.userName}</Table.Cell>
                <Table.Cell>{record.appName}</Table.Cell>
                <Table.Cell>{record.changedAt}</Table.Cell>
                <Table.Cell>{record.text}</Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </Tab.Pane>
  )
}