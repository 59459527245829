import { Menu, Dropdown } from 'semantic-ui-react'
import { Link, NavLink } from 'react-router-dom';

function MainMenu() {
  return (
    <Menu stackable>
      <Menu.Item><Link to="/">Home</Link></Menu.Item>
      <Menu.Item><NavLink to="/list-slip">List Slip</NavLink></Menu.Item>
      {/* <Menu.Item><NavLink to="/print-map">Print Map</NavLink></Menu.Item> */}
      <Menu.Item><NavLink to="/print-mode">Print Mode</NavLink></Menu.Item>
      {/* <Menu.Item><NavLink to="/customers">Customers</NavLink></Menu.Item> */}
      <Menu.Item><NavLink to="/orders">Orders</NavLink></Menu.Item>
      {/* <Menu.Item><NavLink to="/orders-report">Orders Report</NavLink></Menu.Item> */}
      <Menu.Item><NavLink to="/products">Products</NavLink></Menu.Item>
      {/* <Menu.Item><NavLink to="/wc-sync">WC Sync</NavLink></Menu.Item> */}
      <Menu.Item><NavLink to="/print-files">Print Files</NavLink></Menu.Item>
      {/* <Menu.Item><NavLink to="/shipping-routes">Shipping Routes</NavLink></Menu.Item> */}
      <Menu.Item><NavLink to="/users">Users</NavLink></Menu.Item>
      {/* <Menu.Item><NavLink to="/payment-report">Payment Report</NavLink></Menu.Item> */}
      {/* <Menu.Item><NavLink to="/orders-bulk-action">Orders Bulk Action</NavLink></Menu.Item> */}
      {/* <Menu.Item><NavLink to="/pnp-logs">PNP Logs</NavLink></Menu.Item> */}
      <Menu.Item><NavLink to="/assembly-list">Assembly List</NavLink></Menu.Item>
      <Menu.Item><NavLink to="/breakdown-list">Breakdown List</NavLink></Menu.Item>
      <Menu.Item><NavLink to="/breakdown-co">Customer Order breakdown</NavLink></Menu.Item>
      <Dropdown item text='More...'>
        <Dropdown.Menu>
          <Dropdown.Item><NavLink to="/print-map">Print Map</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/customers">Customers</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/orders-report">Orders Report</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/wc-sync">WC Sync</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/shipping-routes">Shipping Routes</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/payment-report">Payment Report</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/orders-bulk-action">Orders Bulk Action</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/pnp-logs">PNP Logs</NavLink></Dropdown.Item>
          <Dropdown.Item><NavLink to="/review">Review</NavLink></Dropdown.Item>
          {/* {
            process.env.RAZZLE_LC_STAGE_VERSION === 'dev' && (
              <Dropdown.Item><NavLink to="/review">Review</NavLink></Dropdown.Item>
            )
          } */}
        </Dropdown.Menu>
      </Dropdown>
    </Menu>
  )
}

export default MainMenu;