import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Menu, Dropdown, Button, Modal, Input, Checkbox, TableCell } from 'semantic-ui-react';
import SemanticDatepicker from 'react-semantic-ui-datepickers';
import { gql, useQuery, useLazyQuery, useApolloClient } from '@apollo/client';
import format from 'date-fns/format';
import add from 'date-fns/add';
import sub from 'date-fns/sub';

import PrivateRoute from 'ui/components/PrivateRoute';

import MainMenu from '../../components/MainMenu';

const domain = createDomain('Assembly List (Page)');
export const onPageStarted = domain.event();

const listProductsQuery = gql`
  query QueryListProductsExt($data: ListProductsInputExt) {
    listProductsExt(data: $data) {
      products
      {
        id
        name
        sku
      }
      total
      range
    }
  }
`;

// const listAssemblyInfoQuery = gql`
//   query QueryAssemblyList($from: String!, $to: String!, $source: String) {
//     assemblyList(from: $from, to: $to, source: $source) {
//       id
//       sku
//       date
//       source
//       count
//       orders
//     }
//   }
// `;

const ASSEMBLY_INFO_FRAGMENT = gql`
  fragment AssemblyInfoFields on AssemblyInfo {
    key
    sku
    date
    source
    count
    orders
  } 
`;

const listAssemblyInfoQuery = gql`
  ${ASSEMBLY_INFO_FRAGMENT}
  query QueryAssemblyList($data: AssemblyListInput) {
    listAssemblyInfo(data: $data) {
      meals {
        ...AssemblyInfoFields
      }
      components {
        ...AssemblyInfoFields
      }
      orders
      pages
      page
      duration
    }
  }
`;

export function PageAssemblyList(props) {
  const [fromDate, setFromDate] = React.useState(sub(new Date(), { days: 1 }));
  const [toDate, setToDate] = React.useState(add(new Date(), { days: 1 }));
  const [loading, setLoading] = React.useState(null);
  const [coreData, setCoreData] = React.useState();
  const [woocomData, setWoocomData] = React.useState();
  const client = useApolloClient();
  const [updatedAt, setUpdatedAt] = React.useState();


  const { error: productError, data: productData } = useQuery(listProductsQuery, {
    errorPolicy: 'all',
    variables: {
      data: {
        filter: {
          categoryName: 'components',
          hiddenInPnp: false
        }
      }
    }
  });

  const products = React.useMemo(() => {
    if (productData) {
      return productData?.listProductsExt?.products
        .map(p => p)
        .sort((a, b) => `${a.name}`
          .localeCompare(`${b.name}`));
    }
    return [];
  }, [productData]);

  const handleMakeReport = async () => {
    const requestData = ({ page, perPage, source }) => {
      return client.query({
        query: listAssemblyInfoQuery, variables: {
          data: {
            filter: {
              from: format(fromDate, "yyyy-MM-dd"),
              to: source === 'wc' ? format(add(toDate, { days: 1 }), "yyyy-MM-dd") : format(toDate, "yyyy-MM-dd"),
              withoutQa: true,
              source
            },
            page,
            perPage
          }
        }
      })
    }
    setLoading('Loading data from CORE');
    const responseCore = await requestData({ page: 1, perPage: 1000, source: 'core' });
    // console.log('---make report core---', responseCore.data);
    setCoreData(responseCore.data?.listAssemblyInfo);
    setLoading('Loading data from WC');
    let wcPage = 1;
    let stopRequest = false;
    const wcData = {
      components: [],
      meals: [],
      orders: [],
    };
    do {
      const responseWc = await requestData({ page: wcPage, perPage: 10, source: 'wc' });
      const { pages, components, meals, orders } = responseWc.data.listAssemblyInfo;
      wcData.orders = [...wcData.orders, ...orders];
      meals.forEach(meal => {
        const foundData = wcData.meals.find(d => d.key === meal.key);
        if (foundData) {
          foundData.count += meal.count;
          foundData.orders = [...foundData.orders, ...meal.orders];
        } else {
          wcData.meals.push({ ...meal });
        }
      })
      components.forEach(component => {
        const foundData = wcData.components.find(d => d.key === component.key);
        if (foundData) {
          foundData.count += component.count;
          foundData.orders = [...foundData.orders, ...component.orders];
        } else {
          wcData.components.push({ ...component });
        }
      })
      setLoading(`Loading data from WC ${Number(wcPage / pages * 100).toFixed(0)}%`);
      wcPage++;
      stopRequest = pages < wcPage;
    } while (!stopRequest);
    setWoocomData(wcData);
    // console.log('---make report wc---', wcData);

    setLoading(null);
    setUpdatedAt(new Date());
  }

  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={!!loading} inverted>
        <Loader>{loading}</Loader>
      </Dimmer>
      <TransitionablePortal open={!!productError}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(productError, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <SemanticDatepicker placeholder="From Date" clearable value={fromDate} onChange={(e, { value }) => setFromDate(value)} />
          <SemanticDatepicker placeholder="To Date" clearable value={toDate} onChange={(e, { value }) => setToDate(value)} />
          <Button content="Load Data" onClick={handleMakeReport} />
        </Menu.Item>
      </Menu>
      {
        updatedAt && <div>Data Loaded: {JSON.stringify(updatedAt)}</div>
      }
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>#</Table.HeaderCell>
            <Table.HeaderCell>Component Name</Table.HeaderCell>
            <Table.HeaderCell>In WC</Table.HeaderCell>
            <Table.HeaderCell>In CORE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            products.map((product, ind) => (
              <Table.Row key={product.id}>
                <Table.Cell>{ind + 1}</Table.Cell>
                <Table.Cell>{product.name} ({product.sku})</Table.Cell>
                <Table.Cell><SkuStatBySource sku={product.sku} data={woocomData?.components} /></Table.Cell>
                <Table.Cell><SkuStatBySource sku={product.sku} data={coreData?.components} data2={woocomData?.components} /></Table.Cell>
              </Table.Row>
            ))
          }
          <Table.Row>
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
            <Table.Cell />
          </Table.Row>
          <Table.Row>
            <Table.Cell></Table.Cell>
            <Table.Cell>Total</Table.Cell>
            <Table.Cell><SumBySource data={woocomData?.components} /></Table.Cell>
            <Table.Cell><SumBySource data={coreData?.components} data2={woocomData?.components} /></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      <Table striped>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell />
            <Table.HeaderCell>In WC</Table.HeaderCell>
            <Table.HeaderCell>In CORE</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>Number of Orders</Table.Cell>
            <Table.Cell>{woocomData?.orders?.length}</Table.Cell>
            <Table.Cell><Diff a={coreData?.orders?.length} b={woocomData?.orders?.length} /></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total number of meals of Orders</Table.Cell>
            <Table.Cell><SumBySource data={woocomData?.meals} /></Table.Cell>
            <Table.Cell><SumBySource data={coreData?.meals} data2={woocomData?.meals} /></Table.Cell>
          </Table.Row>
          <Table.Row>
            <Table.Cell>Total number of meals of components</Table.Cell>
            <Table.Cell><SumBySource data={woocomData?.components} /></Table.Cell>
            <Table.Cell><SumBySource data={coreData?.components} data2={woocomData?.components} /></Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      <br />
      {(woocomData && coreData) &&
        <div>
          <div>Missed orders in CORE {JSON.stringify(woocomData.orders.filter(a => !coreData.orders.includes(a)))}</div>
          <div>Missed orders in WC: {JSON.stringify(coreData.orders.filter(a => !woocomData.orders.includes(a)))}</div>
        </div>
      }
    </PrivateRoute>
  )
}

function SkuStatBySource(props) {
  const { sku, data, data2 } = props;
  const skuData = React.useMemo(() => {
    if (!data) {
      return '??';
    }
    return data?.reduce((m, stat) => {
      if (stat.sku === sku) {
        return m + stat.count;
      }
      return m;
    }, 0);
  }, [data]);

  const skuData2 = React.useMemo(() => {
    if (!data2) {
      return '0';
    }
    return data2?.reduce((m, stat) => {
      if (stat.sku === sku) {
        return m + stat.count;
      }
      return m;
    }, 0);
  }, [data2]);


  if (data && data2) {
    if (skuData !== skuData2) {
      return <span style={{ background: 'red' }}>{skuData}</span>
    }
  }

  return <span>{skuData}</span>
}


function SumBySource(props) {
  const { data, data2 } = props;
  const sumData = React.useMemo(() => {
    if (!data) {
      return '??';
    }
    return data?.reduce((m, stat) => m + stat.count, 0);
  }, [data]);

  const sumData2 = React.useMemo(() => {
    if (!data2) {
      return '0';
    }
    return data2?.reduce((m, stat) => m + stat.count, 0);
  }, [data2]);

  if (data && data2) {
    if (sumData !== sumData2) {
      return <span style={{ background: 'red' }}>{sumData}</span>
    }
  }

  return <span>{sumData}</span>
}

function Diff(props) {
  const { a, b } = props;
  if (a !== b) {
    return <span style={{ background: 'red' }}>{a}</span>
  }
  return <span>{a}</span>
}