import * as React from 'react';
import { createDomain } from 'effector-root';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header, Dropdown, Menu, Button, Modal, Form, Ref } from 'semantic-ui-react';
import { useForm, Controller } from 'react-hook-form';

import MainMenu from '../../components/MainMenu';
import PrivateRoute from '../../components/PrivateRoute';

import { useLazyQuery, useMutation, gql, useQuery } from '@apollo/client';
const domain = createDomain('Users (Page)');
export const onPageStarted = domain.event();

const listUsers = gql`
  query QueryListUsers($data: ListUsersInput) {
    listUsers(data: $data){
      users {
        name
        groups
        email
      }
      total
    }
  }
`;

const listUserGroups = gql`
  query QueryListUserGroups {
    listGroups{
      name
    }
  }
`;

const updateUserMutation = gql`
  mutation updateUser($name: String!, $data: UserInput!) {
    updateUser(name: $name, data: $data) {
      name
      groups
      email
    }
  }
`;

const setUserPasswordMutation = gql`
  mutation setUserPassword($name: String!, $password: String!) {
    setUserPassword(name: $name, password: $password) {
      name
      groups
      email
    }
  }
`;

const createUserMutation = gql`
  mutation createUser($data: UserInput!) {
    createUser(data: $data) {
      name
      groups
      email
    }
  }
`;

const deleteUserMutation = gql`
  mutation deleteUser($name: String!) {
    removeUser(name: $name) 
  }
`;

export function PageUsers(props) {
  const [getUsers, { loading, error, data, refetch }] = useLazyQuery(listUsers);
  const [selectedUser, setSelectedUser] = React.useState();
  const [createNewUser, setCreateNewUser] = React.useState(false);
  const [newPassword, setNewPassword] = React.useState(false);

  React.useEffect(() => {
    getUsers({ variables: { data: { range: [0, 1000] } } });
  }, []);

  return (
    <PrivateRoute groups={['Admin']} >
      <MainMenu />
      <Dimmer active={loading && !data} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Menu compact>
        <Menu.Item>
          <Button onClick={() => setCreateNewUser(true)}>Add new User</Button>
        </Menu.Item>
      </Menu>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Name</Table.HeaderCell>
            <Table.HeaderCell>email</Table.HeaderCell>
            <Table.HeaderCell>Groups</Table.HeaderCell>
            <Table.HeaderCell>Action</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listUsers?.users?.map((user, ind) => (
              <Table.Row key={user.name ?? ind}>
                <Table.Cell>{user?.name}</Table.Cell>
                <Table.Cell>{user?.email}</Table.Cell>
                <Table.Cell>{JSON.stringify(user?.groups)}</Table.Cell>
                <Table.Cell>
                  <Button content="Edit" size="tiny" onClick={() => setSelectedUser(user)} />
                  <Button content="Set Password" size="tiny" onClick={() => setNewPassword(user)} />
                </Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
      <EditUser user={selectedUser} onClose={setSelectedUser} usersRefetch={refetch} />
      <NewUser user={createNewUser} onClose={setCreateNewUser} usersRefetch={refetch} />
      <SetNewPassword user={newPassword} onClose={setNewPassword} usersRefetch={refetch} />
    </PrivateRoute>
  )
}

function EditUser(props) {
  const { user, onClose, usersRefetch } = props;
  const { data: groupsData } = useQuery(listUserGroups);
  const [updateUser, { loading: loadingUpdateUser }] = useMutation(updateUserMutation);
  const [deleteUser, { loading: loadingDeleteUser, error: deleteUserError }] = useMutation(deleteUserMutation);
  const { handleSubmit, formState, watch, reset, control, getValues } = useForm({
    reValidateMode: 'onChange'
  });
  const { isSubmitting } = formState;
  const [openConfirmDialog, setOpenConfirmDialog] = React.useState(false);

  const groupOptions = React.useMemo(() => {
    if (groupsData?.listGroups) {
      return groupsData?.listGroups.map(g => ({ key: g.name, text: g.name, value: g.name }))
    }
    return [];
  }, [groupsData]);

  React.useEffect(() => {
    if (user) {
      reset({
        groups: user.groups ?? []
      })
    }
  }, [user]);

  const onSubmit = async (formData) => {
    await updateUser({ variables: { name: user.name, data: { groups: formData.groups } } });
    onClose(null);
    usersRefetch();
  }

  const handleDeleteUser = async () => {
    const resp = await deleteUser({ variables: { name: user.name } });
    if (!resp?.errors?.length) {
      setOpenConfirmDialog(false);
      onClose(null);
      usersRefetch();
    }
  }

  return (
    <Modal open={!!user} dimmer="blurring" as={Form} onSubmit={handleSubmit(onSubmit)} loading={isSubmitting}>
      <Modal.Header>
        Details of {user?.name}
      </Modal.Header>
      <Modal.Content>
        <Controller
          name="groups"
          control={control}
          render={({ field: { ref, onChange, ...other } }) => (
            <Ref innerRef={ref}>
              <Form.Select {...other} onChange={(e, { value }) => onChange(value)} label="Groups" placeholder="user groups" options={groupOptions} multiple clearable />
            </Ref>
          )}
        />
      </Modal.Content>
      <Modal.Actions>
        <Button content="Update" type="submit" />
        <Button content="Delete User" type="button" onClick={() => setOpenConfirmDialog(true)} />
        <Button content="Cancel" type="button" onClick={() => onClose(null)} />
      </Modal.Actions>
      <Modal open={openConfirmDialog} onClose={() => setOpenConfirmDialog(false)} size="small">
        <Modal.Header>
          Do you want to delete this user "{user?.name}" ?
        </Modal.Header>
        <Modal.Content>
          <div style={{ color: 'red' }}>{JSON.stringify(deleteUserError?.message)}</div>
        </Modal.Content>
        <Modal.Actions>
          <Button content="Yes" type="button" onClick={() => handleDeleteUser()} />
          <Button content="No" type="button" onClick={() => setOpenConfirmDialog(false)} />
        </Modal.Actions>
      </Modal>
    </Modal>
  )
}

function NewUser(props) {
  const { user, onClose, usersRefetch } = props;
  const [createUser, { loading, error, data }] = useMutation(createUserMutation, {
    errorPolicy: 'all'
  });
  const { handleSubmit, formState, watch, reset, control, getValues, trigger } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      name: '',
      email: ''
    }
  });
  const { isSubmitting, isValid } = formState;

  React.useEffect(() => {
    if (user) {
      reset({
        name: '',
        email: ''
      })
    }
  }, [user]);

  const onSubmit = async (formData) => {
    const resp = await createUser({ variables: { data: { name: formData.name, email: formData.email } } });
    if (!resp.errors?.length) {
      onClose(false);
      usersRefetch();
    }
  }

  return (
    <Modal open={!!user} dimmer="blurring" as={Form} onSubmit={handleSubmit(onSubmit)} loading={isSubmitting || loading}>
      <Modal.Header>
        Add new User
      </Modal.Header>
      <Modal.Content>
        <Controller
          name="name"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...other } }) => (
            <Ref innerRef={ref}>
              <Form.Input {...other} onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="User Name" placeholder="user name" />
            </Ref>
          )}
        />
        <Controller
          name="email"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...other } }) => (
            <Ref innerRef={ref}>
              <Form.Input {...other} onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="User email" placeholder="user email" />
            </Ref>
          )}
        />
        <div style={{ color: 'red' }}>{JSON.stringify(error?.message)}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Add" type="submit" disabled={!isValid} />
        <Button content="Cancel" type="button" onClick={() => onClose(null)} />
      </Modal.Actions>
    </Modal>
  )
}

function SetNewPassword(props) {
  const { user, onClose, usersRefetch } = props;
  const [setUserPassword, { loading, error, data }] = useMutation(setUserPasswordMutation, {
    errorPolicy: 'all'
  });
  const { handleSubmit, formState, watch, reset, control, getValues, trigger } = useForm({
    reValidateMode: 'onChange',
    defaultValues: {
      password: '',
    }
  });
  const { isSubmitting, isValid } = formState;

  React.useEffect(() => {
    if (user) {
      reset({
        password: ''
      })
    }
  }, [user]);

  const onSubmit = async (formData) => {
    const resp = await setUserPassword({ variables: { name: user.name, password: formData.password } });
    if (!resp.errors?.length) {
      onClose(false);
      usersRefetch();
    }
  }

  return (
    <Modal open={!!user} dimmer="blurring" as={Form} onSubmit={handleSubmit(onSubmit)} loading={isSubmitting || loading}>
      <Modal.Header>
        Set Password for {user?.name}
      </Modal.Header>
      <Modal.Content>
        <Controller
          name="password"
          control={control}
          rules={{ required: true }}
          render={({ field: { ref, onChange, ...other } }) => (
            <Ref innerRef={ref}>
              <Form.Input {...other} type="password" onChange={(e, { name, value }) => { onChange(value); trigger(name); }} label="Password" placeholder="Password" />
            </Ref>
          )}
        />
        <div style={{ color: 'red' }}>{JSON.stringify(error?.message)}</div>
      </Modal.Content>
      <Modal.Actions>
        <Button content="Set Password" type="submit" disabled={!isValid} />
        <Button content="Cancel" type="button" onClick={() => onClose(null)} />
      </Modal.Actions>
    </Modal>
  )
}

