import { createDomain } from 'effector-root'

import * as fromProducts from 'feature/orders'

const domain = createDomain(`Slip (Model)`)

export const onPageStarted = domain.event()
export const onQuery = domain.event()
export const onSet = domain.event()
export const onReset = domain.event()

export const onPrint = domain.event()

export const onDateStartChange = domain.event()
export const onDateEndChange = domain.event()
const getDate = (n) =>
  new Date(new Date().setDate(new Date().getDate() + n))
    .toISOString()
    .split('T')[0]

export const $dateStart = domain.store(getDate(-1))
export const $dateEnd = domain.store(getDate(1))

$dateStart.on(onDateStartChange, (_, v) => v)
$dateEnd.on(onDateEndChange, (_, v) => v)

export const $products = domain.store([])
export const $activeMap = domain.store([])
export const $pending = domain.store(true)
export const $printPending = domain.store(false)
