import * as React from 'react';
import { createDomain } from 'effector-root';
import { useQuery, gql } from '@apollo/client';
import { Table, Dimmer, Loader, TransitionablePortal, Segment, Header } from 'semantic-ui-react';

import PrivateRoute from 'ui/components/PrivateRoute';
import MainMenu from '../../components/MainMenu';

const domain = createDomain('Print files (Page)');
export const onPageStarted = domain.event();


const listPrintFiles = gql`
  query Printer_listPrintFiles($data: ListPrintFilesInput) {
    listPrintFiles(data: $data){
      files {
        name
        url
        modified
      }
      total
    }
  }`;


export function PrintFiles(props) {
  const { loading, error, data } = useQuery(listPrintFiles, { variables: { data: { range: [0, 100] } } });
  return (
    <PrivateRoute>
      <MainMenu />
      <Dimmer active={loading} inverted>
        <Loader />
      </Dimmer>
      <TransitionablePortal open={!!error}>
        <Segment style={{
          margin: 15,
          position: 'fixed',
          zIndex: 1000,
        }}>
          <Header>Error:</Header>
          <p>
            {JSON.stringify(error, null, ' ')}
          </p>
        </Segment>
      </TransitionablePortal>
      <Table compact>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>File</Table.HeaderCell>
            <Table.HeaderCell>Modified</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {
            data?.listPrintFiles?.files?.map(file => (
              <Table.Row key={file.name}>
                <Table.Cell><a href={file.url} target="_blank">{file.name}</a></Table.Cell>
                <Table.Cell>{file.modified}</Table.Cell>
              </Table.Row>
            ))
          }
        </Table.Body>
      </Table>
    </PrivateRoute>
  )
}
